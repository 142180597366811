import Api from './Api'

const api = Api()

const Datenkarte = (params) => {
  return {
    ...params,
    save: async function () {
      const data = Object.entries(this).reduce((acc, [key, value]) => {
        if (['boolean', 'string', 'number'].includes(typeof value) || value === null ) {
          acc[key] = typeof value === 'string' ? value.trim() : value
        }
        return acc
      }, {})
      const res = await api.exec(`/datenkarte/${params.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(data)
        })
      if (res.error) {
        throw new Error(res.error)
      }
      return Datenkarte(res)
    },
    destroy: async () => {
      const res = await api.exec(`/datenkarte/${params.id}`,
        {
          method: 'DELETE',
          body: JSON.stringify({ ...params })
        })
      if (res.error) {
        throw new Error(res.error)
      }
      return res
    }
  }
}

export { Datenkarte }

export default {
  create: async (params) => {
    const res = await api.exec('/datenkarte', {
      method: 'POST',
      body: JSON.stringify(params)
    })
    if (res.error) {
      throw new Error(res.error)
    }
    return Datenkarte(res.datenkarte)
  },
  findAll: async (params) => {
    const res = await api.exec('/datenkarten', {
      method: 'GET',
      body: JSON.stringify(params)
    })
    if (res.error) {
      return res.error
    }
    if (res.datenkarte_list.length < 1) {
      return []
    }
    return res.datenkarte_list.map(datenkarte => Datenkarte(datenkarte))
  },
  findOne: async (id) => {
    const res = await api.exec(`/datenkarte/${encodeURIComponent(id)}`, {
      method: 'GET'
    })
    if (res.error) {
      throw new Error(res.error)
    }
    return Datenkarte(res.datenkarte)
  }
}
