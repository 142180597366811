import React from 'react'
import styled from 'styled-components'
import Theme from './Theme'
import '@github/markdown-toolbar-element'

import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'

const Textarea = ({ formatText, placeholder, titlePlaceholder, titleDefaultValue, onTitleChange, id, defaultValue, onChange }, props) => {
  return (
    <>
      <ToolbarMarkdownWrapper>
        <TitleInput
          placeholder={titlePlaceholder}
          onChange={onTitleChange}
          defaultValue={titleDefaultValue}
        />
        <markdown-toolbar for={id}>
          <StyledList>
            <md-ordered-list>
              <FormatListNumberedIcon />
            </md-ordered-list>
          </StyledList>
          <StyledItalic>
            <md-italic>
              <FormatItalicIcon />
            </md-italic>
          </StyledItalic>
          <StyledBold>
            <md-bold>
              <FormatBoldIcon />
            </md-bold>
          </StyledBold>
        </markdown-toolbar>
      </ToolbarMarkdownWrapper>
      <StyledTextarea id={id} placeholder={placeholder} defaultValue={defaultValue} onChange={onChange} />
    </>
  )
}

const StyledTextarea = styled.textarea`
  border: 1px solid ${Theme.color6};
  border-radius: 4px;
  box-sizing: border-box;
  background: #FFF;
  width: 100%;
  resize: none;
  padding: 8px 20px 20px 10px;
  color: ${Theme.color1};
  outline: none;
  font-size: 16px;
  min-height: 140px;
  line-height: 22px;
`

const StyledBold = styled.div`
  font-weight: bold
  float: right;
  margin-right: 5px;
  cursor: pointer;
  width: 24px;
  margin-top: 3px;
`

const StyledItalic = styled.div`
  font-weight: bold
  float: right;
  margin-right: 5px;
  cursor: pointer;
  width: 24px;
  margin-top: 3px;
`

const StyledList = styled.div`
  font-weight: bold
  float: right;
  margin-right: 5px;
  cursor: pointer;
  width: 24px;
  margin-top: 3px;
`

const ToolbarMarkdownWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: flex-end;
`

const TitleInput = styled.input`
  margin-right: 40px;
  margin-bottom: 5px;
  min-width: 30px;
  height: 35px;
  font-size: 16px;
  flex: 1;
  border: 1px solid ${Theme.color6};
  border-radius: 4px;
  box-sizing: border-box;
  background: #FFF;
  resize: none;
  padding: 0px 10px;
  color: ${Theme.color1};
  outline: none;
`

export default Textarea
