import React from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root')

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: '80'
  },
  content: {
    marginTop: '88px',
    zIndex: '90'
  }
}

const StyledModal = styled(ReactModal)`
  width: 624px;
  max-width: 624px;
  margin: 0 auto;
  outline: none;
`

const NotificationContainer = styled.div`
  > div {
    margin-bottom: 10px;
  }
`

const ModalContent = styled.div`
  border-radius: 2px;
  background: #FFF;
  box-shadow: 0 8px 16px 0 #E0E2E4;
  width: auto;
  padding: 30px 20px;
  max-width: 624px;
  box-sizing: border-box;
`

const Modal = ({ isOpen, close, ...props }) => {
  return (
    <StyledModal
      shouldCloseOnOverlayClick
      onRequestClose={close}
      isOpen={isOpen}
      style={customStyles}
    >
      {props.notifications &&
        <NotificationContainer>
          {props.notifications}
        </NotificationContainer>}

      <ModalContent>
        {props.children}
      </ModalContent>
    </StyledModal>
  )
}

export default Modal
