import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import DataSource from '../common/ApiHandler/TestReportTemplates'

import List from './BuilderInput'

import Theme from '../common/Theme'
import Button from '../common/Button'
import TextInput from '../common/TextInput'
import Spacer from '../common/Spacer'
import Notification from '../common/Notification'
import Breadcrumbs from '../common/Breadcrumbs'

import Grid from '@material-ui/core/Grid'
import Modal from '../common/Modal'
import Destroy from './Destroy'

import RadioButtons from '../common/RadioButton'

import translateErrorMessage from '../common/translate/errorMessage'
import translateDatetime from '../common/translate/datetime.js'

const Edit = (props, { match, ...propes }) => {
  const [templateData, setTemplateData] = useState({
    title: '',
    bemerkung: '',
    typ: 'medizinprodukt',
    published: false
  })
  const [mode, setMode] = useState('create')
  const [notification, setNotification] = useState()
  const [loading, setLoading] = useState(false)
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false)
  const [triggerSave, setTriggerSave] = useState(false)
  const templateId = props.match.params.id || false

  /** Effects and Dataload */
  useEffect(() => {
    if (loading === false) {
      if (templateId) {
        loadData()
      }
    }
    // eslint-disable-next-line
  }, [templateId])

  const loadData = async () => {
    try {
      setLoading(true)
      const id = templateId
      const template = await DataSource.findOne(id)
      if (window.location.hash.includes('successDuplicate')) {
        onNotification('Die Prüfprotokollvorlage wurde erfolgreich dupliziert.', 'positive')
      }
      setMode('update')
      setTemplateData(template)
      setLoading(false)
    } catch (err) {
      onNotification('Die Prüfprotokollvorlage wurde nicht gefunden.', 'negative')
    }
  }

  /* Form Handling; Data Submit */
  const saveTemplate = async () => {
    try {
      let res
      if (mode === 'create') {
        res = await DataSource.create(templateData)
        setTriggerSave(res)
      }
      if (mode === 'update') {
        res = await templateData.save()
        setTriggerSave(true)
      }
      if (res) {
        if (mode === 'update') {
          props.history.push('/pruefprotokollvorlagen?successUpdate')
        } else {
          props.history.push('/pruefprotokollvorlagen?successCreate')
        }
      }
    } catch (err) {
      onNotification(translateErrorMessage(err.toString()), 'negative')
    }
  }

  const onDestroy = async () => {
    props.history.push('/pruefprotokollvorlagen?successDelete')
  }

  const handleChange = (field, value) => {
    setTemplateData({ ...templateData, [field]: value })
  }

  const handleTypChange = (value) => {
    setTemplateData({ ...templateData, typ: value })
  }

  const handlePublishedChange = (value) => {
    setTemplateData({ ...templateData, published: value })
  }

  const onNotification = async (message, type) => {
    setNotification(
      <Notification close={close} type={type}>{message}</Notification>
    )
  }

  const close = async () => {
    await setNotification(null)
  }

  const BreadcrumbDisplay = ({ mode }) => {
    return (
      <>
        {mode === 'create' &&
          <Breadcrumbs items={[
            {
              path: '/pruefprotokollvorlagen',
              title: 'Prüfprotokollvorlagen'
            },
            {
              title: 'Neu'
            }
          ]}
          />}
        {mode === 'update' &&
          <Breadcrumbs items={[
            {
              path: '/pruefprotokollvorlagen',
              title: 'Prüfprotokollvorlagen'
            },
            {
              title: 'Bearbeiten'
            }
          ]}
          />}
        <Spacer />
      </>
    )
  }

  const AdditionalEditForm = () => {
    const date = translateDatetime(templateData.createdAt)
    return (
      <InfoHeading>
        <Grid
          container
          justify='space-between'
          alignItems='center'
        >
          <Grid item xs={10}>
            <InfoText>Erstellt am {date}</InfoText>
          </Grid>
          <Grid item xs={2}>
            <Button primary fullWidth onClick={() => setDisplayDeleteModal(true)}>
              Löschen
            </Button>
          </Grid>
        </Grid>
      </InfoHeading>
    )
  }

  return (
    <>
      {displayDeleteModal &&
        <Modal isOpen={displayDeleteModal}>
          <Destroy template={templateData} onCancel={() => setDisplayDeleteModal(false)} onDestroy={onDestroy} />
        </Modal>}
      <Grid container alignItems='center' spacing={3}>
        <Grid item xs={12}>
          <BreadcrumbDisplay mode={mode} />
          {notification &&
            <>
              {notification}
              <Spacer vertical='20px' />
            </>}
          {mode === 'update' &&
            <AdditionalEditForm />}
          <Grid item xs={12}>
            <Grid
              container spacing={5}
              justify='center'
              alignItems='center'
            >
              <Grid item xs={2}>
                <InputLabel style={{ paddingBottom: '12px' }}>Titel:</InputLabel>
              </Grid>
              <Grid item xs={10}>
                <TextInput placeholder='Titel' value={templateData.title} onChange={e => handleChange('title', e.target.value)} />
              </Grid>
            </Grid>
            <Spacer vertical='20px' />
            <Grid container spacing={5}>
              <Grid item xs={2} md={2}><InputLabel>Typ:</InputLabel></Grid>
              <Grid item xs={10} md={4}>
                <RadioButtons
                  style={{ maxWidth: '300px' }}
                  defaultValue={templateData.typ}
                  onChange={handleTypChange}
                  options={[
                    {
                      label: 'Medizinprodukt',
                      value: 'medizinprodukt'
                    },
                    {
                      label: 'Fahrzeug',
                      value: 'fahrzeug'
                    }]}
                />
              </Grid>
              <Grid item xs={2} md={2}><InputLabel>Status:</InputLabel></Grid>
              <Grid item xs={10} md={4}>
                <RadioButtons
                  style={{ maxWidth: '300px' }}
                  defaultValue={templateData.published}
                  onChange={handlePublishedChange}
                  options={[
                    {
                      label: 'Unveröffentlicht',
                      value: false
                    },
                    {
                      label: 'Öffentlich',
                      value: true
                    }]}
                />
              </Grid>
            </Grid>
            <Spacer vertical='30px'>&nbsp;</Spacer>
            <Grid container direction='row'>
              <Grid item xs={2}>&nbsp;</Grid>
              <Grid item xs={10}>
                <List templateData={templateData} triggerSave={triggerSave} onNotification={onNotification} />
              </Grid>
            </Grid>
            <Spacer vertical='15px' />
            <Grid container direction='row' justify='flex-end'>
              <Grid item xs={10}>
                <Grid container direction='row' justify='space-between'>
                  <Grid item xs={4}>
                    <Button fullWidth to='/pruefprotokollvorlagen' primary>Abbrechen</Button>
                  </Grid>
                  <Grid container item xs={4} justify='flex-end'>
                    <Button primary isActive fullWidth onClick={saveTemplate}>{mode === 'create' ? 'Erstellen' : 'Speichern'}</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const InputLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  display: block;
`
const InfoHeading = styled.div`
  padding-bottom: 20px;
`

const InfoText = styled.span`
  font-size: 14px;
  color: ${Theme.color1};
`

export default Edit
