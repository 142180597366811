import React from 'react'
import styled from 'styled-components'
import IconLogo from '../../assets/images/logo.svg'
import Grid from '@material-ui/core/Grid'

const Footer = () => {
  return (
    <Wrapper className='no-print'>
      <Inner>
        <MaxWidth>
          <Logo>
            <img src={IconLogo} alt='Logo' />
          </Logo>
          <Grid container>
            <Grid item xs={12} lg={4}>
              <Listitem target='_blank' rel='noopener noreferrer' href='http://www.seewald24.de/Datenschutzerklaerung_seewald.pdf'>Datenschutzerklärung</Listitem>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Listitem target='_blank' rel='noopener noreferrer' href='http://www.seewald24.de/index.php/impressum'>Impressum</Listitem>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Listitem target='_blank' rel='noopener noreferrer' href='http://www.seewald24.de/index.php/kontakt-3'>Kontakt</Listitem>
            </Grid>
          </Grid>
        </MaxWidth>
      </Inner>
    </Wrapper>
  )
}

const MaxWidth = styled.div`
  max-width: 91.67%;
  margin: 0 auto;
`

const Inner = styled.div`
  max-width: 1540px;
  margin: 0 auto;
  padding: 30px 0px 20px 0px;
`

const Wrapper = styled.div`
  background-color: #CDCDCD;
  margin-top: 30px;
  box-sizing: border-box;
  width: 100%;
`

const Logo = styled.div`
  img {
    max-width: 130px;
  }
  padding-bottom: 20px;
  border-bottom: 1px solid #FFF;
`

const Listitem = styled.a`
  display: block;
  color: #FFF;
  text-decoration: none;
  font-style: italic;
  font-size: 18px;
  line-height: 24px;
  padding-top: 20px;
`

export default Footer
