import * as React from 'react'

const Delete = (props) => {
  return (
    <svg width={props.width || '14'} height={props.height || '18'} viewBox='0 0 14 18' {...props}>
      <path
        d='M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4H1v12zM14 1h-3.5l-1-1h-5l-1 1H0v2h14V1z'
        fill={props.fill || '#CDCDCD'}
        fillRule='evenodd'
      />
    </svg>
  )
}

export default Delete
