import Api from './Api'

const api = Api()

const Inspectationfield = (params) => {
  return {
    ...params,
    save: async function () {
      const res = await api.exec(`/vorlagenbaustein/${params.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(this)
        })
      if (res.error) {
        throw new Error(res.error)
      }
      return Inspectationfield(res)
    },
    destroy: async () => {
      const res = await api.exec(`/vorlagenbaustein/${params.id}`,
        {
          method: 'DELETE'
        })
      if (res.error) {
        throw new Error(res.error)
      }
      return res
    }
  }
}

export default {
  create: async (params) => {
    const res = await api.exec('/vorlagenbaustein', {
      method: 'POST',
      body: JSON.stringify(params)
    })
    if (res.error) {
      throw new Error(res.error)
    }
    return Inspectationfield(res.vorlagenbaustein)
  },
  findAll: async (params) => {
    const res = await api.exec('/vorlagenbaustein', {
      method: 'GET',
      body: JSON.stringify(params)
    })
    if (res.error) {
      return res.error
    }
    if (res.vorlagenbaustein_list.length < 1) {
      return []
    }
    return res.vorlagenbaustein_list.map(testReportTemplate => Inspectationfield(testReportTemplate))
  },
  findOne: async (id) => {
    const res = await api.exec(`/vorlagenbaustein/${encodeURIComponent(id)}`, {
      method: 'GET'
    })
    if (res.error) {
      throw new Error(res.error)
    }
    return Inspectationfield(res.vorlagenbaustein)
  }
}
