import React, { useEffect, useState } from 'react'
import Users from '../common/ApiHandler/User'
import Button from '../common/Button'
import PageHeader from '../common/PageHeader'
import Modal from '../common/Modal'
import Spacer from '../common/Spacer'
import Notification from '../common/Notification'

import List from './List'
import Destroy from './Destroy'
import Edit from './Edit'
import Create from './Create'

const UserManagement = () => {
  const [isActive, setActive] = React.useState(false)
  const [users, setUsers] = useState([])
  const [editUser, setEditUser] = useState()
  const [destroyUser, setDestroyUser] = useState()
  const [createUser, setCreateUser] = useState()
  const [notification, setNotification] = useState()
  const [modalNotification, setModalNotification] = useState(false)
  const [loading, setLoading] = useState(false)

  React.useEffect(() => {
    if (!isActive) {
      loadData()
    }
    // eslint-disable-next-line
  }, [isActive])

  const loadData = async () => {
    const UsersApi = Users({})
    const loadedUser = await UsersApi.findAll()
    setUsers(loadedUser)
    setActive(true)
  }

  /**
   * Remove notifications after 10 seconds
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification(false)
    }, 10000)
    return () => clearTimeout(timer)
  }, [notification])

  const refreshUserList = async () => {
    await setNotification(null)
    await resetSelections()
    await setLoading(true)
    const userList = await Users({}).findAll()
    await setUsers(userList)
    await setLoading(false)
  }

  const resetSelections = async () => {
    await setCreateUser(null)
    await setEditUser(null)
    await setDestroyUser(null)
  }

  // Used to display success messages before the list
  const onNotification = async (message, type) => {
    setNotification(
      <Notification close={close} type={type}>{message}</Notification>
    )
  }

  // Used to display failure messages inside the modal container (but outside the white box)
  const onModalNotification = async (message, type) => {
    setModalNotification(
      <Notification close={closeModalNotification} type={type}>{message}</Notification>
    )
  }

  const close = async () => {
    await setNotification(null)
  }

  const closeModalNotification = async () => {
    setModalNotification(null)
  }

  return (
    <>
      <PageHeader>
        <h1>Nutzer</h1>
        <Button alternative onClick={() => setCreateUser({})}>Neu</Button>
      </PageHeader>
      {notification &&
        <>
          <Spacer vertical='20px' />
          {notification}
          <Spacer vertical='20px' />
        </>}
      {loading && <p>Daten werden geladen</p>}

      <List users={users} onSelect={setEditUser} onDestroy={setDestroyUser} />

      <Modal isOpen={(!!createUser || !!editUser || !!destroyUser)} notifications={modalNotification}>
        {!!createUser &&
          <Create
            onCreate={refreshUserList} onNotification={onNotification} onModalNotification={onModalNotification} onCancel={() => {
              setCreateUser(false)
              setModalNotification(false)
            }}
          />}
        {!!editUser &&
          <Edit
            user={editUser} onSave={refreshUserList} onNotification={onNotification} onModalNotification={onModalNotification} onCancel={() => {
              setEditUser(false)
              setModalNotification(false)
            }}
          />}
        {!!destroyUser &&
          <Destroy
            user={destroyUser} onDestroy={refreshUserList} onNotification={onNotification} onModalNotification={onModalNotification} onCancel={() => {
              setDestroyUser(false)
              setModalNotification(false)
            }}
          />}
      </Modal>
    </>
  )
}

export { Create, Edit, List, Destroy }
export default UserManagement
