import React, { useState, useEffect } from 'react'
import Item from './Item'
import DataSource from '../../common/ApiHandler/Inspectationfields'
import LoadingIndicator from '../../common/LoadingIndicator'
import shortid from 'shortid'
import translateErrorMessage from '../../common/translate/errorMessage'
import { Droppable, DragDropContext } from 'react-beautiful-dnd'

import styled from 'styled-components'

const Test = styled.div``

const DEFAULT_ITEM = {
  type: 'default'
}

const List = ({ templateData, triggerSave, onNotification, ...restProps }) => {
  const [originalItems, setOriginalItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [dragableData, setDragableData] = useState({})

  /* Dataload of builder inputs */
  useEffect(() => {
    loadData()
    // eslint-disable-next-line
  }, [templateData.id])

  useEffect(() => {
    if (triggerSave) {
      const toSave = getDiff()
      const templateId = triggerSave.id ? triggerSave.id : templateData.id
      updateTestReportTemplateInputs(templateId, toSave)
    }
    // eslint-disable-next-line
  }, [triggerSave])

  // Receives only items which are new or updated
  const updateTestReportTemplateInputs = async (templateId, inputs) => {
    console.log(inputs)
    await inputs.forEach(async (item) => {
      if (item.type !== 'default') {
        try {
          const fixedItem = {
            ...item,
            typ: item.type,
            pruefprotokollvorlagenId: templateId
          }
          if (fixedItem.id < 36) {
            delete fixedItem.id
          }
          if (fixedItem.id) {
            if (fixedItem.delete) {
              item.destroy()
            } else {
              await fixedItem.save()
            }
          } else {
            await DataSource.create(fixedItem)
          }
        } catch (e) {
          onNotification(`Die Vorlagenbausteine konnten nicht gespeichert werden: ${translateErrorMessage(e.toString())}`)
        }
      }
    })
  }

  const getDiff = () => {
    const diffList = []
    const itemList = Object.values(dragableData.protocols)
    const diffPropsToCheck = ['text', 'freitext', 'einheit', 'title', 'position']

    itemList.forEach(async (protocol) => {
      dragableData.columns['column-1'].protocolIds.forEach(async (id, index) => {
        if (id === protocol.id) {
          if (protocol.position !== index) {
            protocol.position = index
            diffList.push(protocol)
          }
        }
      })
    })

    for (const item of itemList) {
      // is new?
      if (item.type === 'default') continue

      if (!item.id || item.id.length < 36) {
        diffList.push(item)
      }
      // is existing
      if (item.id && !item.id.length < 36) {
        const originalItem = originalItems.find(i => i.id === item.id)
        // Check difference: einheit, text, freitext, position
        if (originalItem) {
          let isChanged = false
          for (const property of diffPropsToCheck) {
            console.log('checking', property, item[property], originalItem[property])
            if (item[property] !== originalItem[property]) {
              isChanged = true
            }
          }
          if (isChanged) {
            diffList.push(item)
          }
        }
      }
    }
    // Find items that should be deleted
    for (const item of originalItems) {
      if (item.type === 'default') continue
      const stillExisting = itemList.findIndex(i => i.id === item.id)
      if (stillExisting === -1) {
        diffList.push({ ...item, delete: true })
      }
    }
    return diffList
  }

  const loadData = async () => {
    await setLoading(true)
    let listOfItems = []
    if (templateData.id) {
      const order = 'position&order=ASC'
      listOfItems = await DataSource.findAll({ pruefprotokollvorlagenId: templateData.id, order })
    }
    listOfItems = translateApi(listOfItems)
    // Always append/prepend default item
    listOfItems.push({ ...DEFAULT_ITEM, position: listOfItems.length + 1 })
    setOriginalItems(listOfItems)
    setDragableData({ ...dragableData, protocols: prepareData(listOfItems, 'id'), columns: prepapreColumnData(listOfItems), columnOrder: ['column-1'] })
    await setLoading(false)
  }

  /**
   * Translate Data for Frontend
   */
  const translateApi = (listOfItems) => {
    return listOfItems.map((item, index) => {
      return {
        ...item,
        type: item.typ || 'default',
        hash: generateItemHash(item.typ || 'default')
      }
    })
  }

  /**
   * action = 'add', 'remove', 'update'
   */
  const onChange = (action, item) => {
    switch (action) {
      case 'add':
        addItem(item)
        break
      case 'remove':
        removeItem(item)
        break
      case 'update':
        updateItem(item)
        break
      default:
        return null
    }
  }

  const addItem = (item) => {
    const { default123, ...listCopy } = dragableData.protocols
    const randomId = Math.random().toString()
    listCopy[randomId] = { type: item.type, hash: generateItemHash(item.type), position: Object.keys(listCopy).length, id: randomId }
    const idArray = dragableData.columns['column-1'].protocolIds
    idArray.splice(idArray.indexOf('default123'), 0, randomId)
    const newColumn = {
      ...dragableData.columns,
      'column-1': {
        protocolIds: idArray
      }
    }
    listCopy.default123 = { type: 'default', position: Object.keys(listCopy).length, id: 'default123' }
    setDragableData({ ...dragableData, protocols: listCopy, columns: newColumn })
  }

  const removeItem = (removeItem) => {
    const protocolsObject = dragableData.protocols
    delete protocolsObject[removeItem.id]
    const idArray = dragableData.columns['column-1'].protocolIds
    idArray.splice(idArray.indexOf(removeItem.id), 1)
    const newColumn = {
      ...dragableData.columns,
      'column-1': {
        protocolIds: idArray
      }
    }
    setDragableData({ ...dragableData, protocols: protocolsObject, columns: newColumn })
  }

  const updateItem = (item) => {
    const itemToBeReplaced = dragableData.protocols[item.id]
    const newProtocols = dragableData.protocols
    newProtocols[itemToBeReplaced.id] = item
    setDragableData({ ...dragableData, protocols: newProtocols })
  }

  const generateItemHash = (type) => `${type}-${shortid.generate()}`

  const prepareData = (listOfItems, key) => {
    listOfItems.forEach(item => {
      if (!item.id && item.type === 'default') {
        item.id = 'default123'
      }
    })
    const initialValue = {}
    return listOfItems.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item
      }
    }, initialValue)
  }

  const prepapreColumnData = (listOfItems) => {
    const protocolIdArray = []
    listOfItems.forEach((item) => {
      protocolIdArray.push(item.id)
    })

    const columnObject = { 'column-1': { id: 'column-1', protocolIds: protocolIdArray, title: 'TEST' } }
    return columnObject
  }

  const onDragEnd = result => {
    const { destination, source, draggableId } = result
    if (!destination) {
      return
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }
    const column = dragableData.columns['column-1']
    const newProtokolIds = Array.from(column.protocolIds)
    newProtokolIds.splice(source.index, 1)
    newProtokolIds.splice(destination.index, 0, draggableId)
    const newColumn = {
      ...column,
      protocolIds: newProtokolIds
    }
    const newDragableData = {
      ...dragableData,
      columns: {
        ...dragableData.columns,
        'column-1': newColumn
      }
    }
    setDragableData(newDragableData)
  }

  return (
    <>
      {dragableData &&
        <DragDropContext
          onDragEnd={onDragEnd}
        >
          <Droppable droppableId='column-1'>
            {provided => (
              <Test ref={provided.innerRef} {...provided.droppableProps}>
                {!loading && dragableData && dragableData.columns && dragableData.protocols && dragableData.columnOrder && dragableData.columnOrder.map(columnId => {
                  const column = dragableData.columns[columnId]
                  const protocols = column.protocolIds.map(protocolId => dragableData.protocols[protocolId])
                  return (
                    protocols.map((protocol, index) => <Item key={protocol.id} index={index} protocol={protocol} onChange={onChange} />)
                  )
                })}

                {loading &&
                  <LoadingIndicator />}
                {provided.placeholder}
              </Test>
            )}
          </Droppable>
        </DragDropContext>}

    </>
  )
}

export default List
