import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Footer from './Footer'
import Blazon from '../../assets/images/blazon.svg'
import BlazonRed from '../../assets/images/blazonred.svg'
import { Link } from 'react-router-dom'
import Abschlussbericht from './Abschlussbericht'
import { Title, Value } from './StyledComponents'
import Grid from '@material-ui/core/Grid'
import Config from '../common/ApiHandler/Config'
import { format as formatDate } from 'date-fns'
import LoadingIndicator from '../common/LoadingIndicator'
import { Datenkarte as APIDatenkarte } from '../common/ApiHandler/Datenkarten'

import { useSession } from '../common/ApiHandler/Session'
import Notification from '../common/Notification'
import translateErrorMessage from '../common/translate/errorMessage'
import TextInput from '../common/TextInput'
import Button from '../common/Button'
import Spacer from '../common/Spacer'
import SessionStore from '../common/ApiHandler/SessionStore'
import ContentEditable from './ContentEditable'

const Datenkarte = (props) => {
  const { session } = useSession()
  const fakeSessionStore = SessionStore('fake')
  const fakeSession = fakeSessionStore.get()
  const [datenkarte, setDatenkarte] = useState({})
  const [letztePruefung, setletztePruefung] = useState(null)
  const [medizinProdukte, setMedizinProdukte] = useState(null)
  const [loading, setLoading] = useState(true)
  const [kdid, setKdid] = useState(fakeSession)
  const [blocking, setBlocking] = useState(!session)
  const [edit, setEdit] = useState(false)
  const [notification, setNotification] = useState()

  const loadData = async () => {
    // use fetch as this happens without login
    try {
      let response = await fetch(`${Config.backend}/datenkarten/${props.match.params.id}`, {
        method: 'GET',
        headers: {
          Authorization: `apikey ${Config.apikey}`,
          Accept: 'application/json'
        }
      })
      if (response.status !== 200) {
        if (response.status === 404) {
          props.history.replace('/not-found')
        }
        throw new Error('Could not get datenkarte')
      }
      response = await response.json()
      if (response.datenkarte.pruefungs && response.datenkarte.pruefungs.length) {
        setletztePruefung(response.datenkarte.pruefungs.find(pruefung => ['positive', 'negative'].includes(pruefung.status)) || null)
      }
      if (response.datenkarte.medizinprodukte && response.datenkarte.medizinprodukte.length) {
        setMedizinProdukte(response.datenkarte.medizinprodukte)
      }
      setDatenkarte(response.datenkarte)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history, props.match.params.id])

  useEffect(() => {
    if (kdid === datenkarte.kdid) {
      setBlocking(false)
    }
  }, [datenkarte, kdid])

  const submit = async (e) => {
    if (kdid === datenkarte.kdid) {
      setBlocking(false)
    }
  }

  const onNotification = async (message, type) => {
    setNotification(
      <Notification close={close} type={type}>{message}</Notification>
    )
  }

  const close = async () => {
    await setNotification(null)
  }

  const handleChange = (event, field) => {
    setKdid(event.target.value)
    fakeSessionStore.set(event.target.value)
  }

  const toggleEdit = async () => {
    if (edit) {
      try {
        await APIDatenkarte(datenkarte).save()
        await loadData()
      } catch (error) {
        onNotification(translateErrorMessage(error.message), 'negative')
      }
    }
    setEdit(!edit)
  }

  const onChangeValue = (key, value) => {
    setDatenkarte({
      ...datenkarte,
      [key]: value
    })
  }

  return (
    <>
      {loading &&
        <LoadingIndicator />}
      {!loading && blocking &&
        <Grid
          container
          justify='center'
          alignItems='center'
        >
          <Grid item xs={12}>&nbsp;</Grid>
          <Grid item xs={8} sm={6} md={5} lg={4}>
            <LoginWrapper onSubmit={(e) => submit(e)}>
              <Spacer vertical='10px' />
              <TextInput labelPosition='top' onChange={(e) => handleChange(e, 'Kundennummer')} label='Kundennummer' />
              <Spacer vertical='20px' />
              <Button fullWidth primary isActive>Einloggen</Button>
            </LoginWrapper>
          </Grid>
        </Grid>}
      {!loading && !blocking &&
        <FixedGrid container spacing={3}>
          <Grid item xs={11}>
            <Headline style={{ paddingTop: '0px' }}>Stammdatenkarte {datenkarte.qrid}</Headline>

            <Headline>Vollständige Datenkarte</Headline>
            {!!session && <Button fullWidth primary isActive onClick={toggleEdit}>{edit ? 'Speichern' : 'Bearbeiten'}</Button>}
            {!!session && <Spacer vertical='20px' />}
            {notification && (
              <Grid item xs={12}>{notification}</Grid>
            )}
            <Box
              style={{
                backgroundColor: edit ? 'rgba(252,130,41,0.25)' : '#FFFFFF'
              }}
            >
              {datenkarte.type === 'fahrzeug' &&
                <>
                  <Title>Kundennummer</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.kdid}
                    node={Value}
                    onChange={(e, value) => onChangeValue('kdid', value)}
                    editable
                  />
                    : <Value>{datenkarte.kdid}</Value>}
                  <Title>Fahrzeugart</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.fahrzeugart}
                    node={Value}
                    onChange={(e, value) => onChangeValue('fahrzeugart', value)}
                    editable
                  />
                    : <Value>{datenkarte.fahrzeugart}</Value>}
                  <Title>Hersteller</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.hersteller}
                    node={Value}
                    onChange={(e, value) => onChangeValue('hersteller', value)}
                    editable
                  />
                    : <Value>{datenkarte.hersteller}</Value>}
                  <Title>Modell</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.model}
                    node={Value}
                    onChange={(e, value) => onChangeValue('model', value)}
                    editable
                  />
                    : <Value>{datenkarte.model}</Value>}
                  <Title>Baujahr</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.baujahr}
                    node={Value}
                    onChange={(e, value) => onChangeValue('baujahr', value)}
                    editable
                  />
                    : <Value>{datenkarte.baujahr}</Value>}
                  <Title>Aufbau</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.aufbau}
                    node={Value}
                    onChange={(e, value) => onChangeValue('aufbau', value)}
                    editable
                  />
                    : <Value>{datenkarte.aufbau}</Value>}
                  <Title>Ausbauer</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.ausbauer}
                    node={Value}
                    onChange={(e, value) => onChangeValue('ausbauer', value)}
                    editable
                  />
                    : <Value>{datenkarte.ausbauer}</Value>}
                  <Title>Ausbaunummer</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.ausbaunummer}
                    node={Value}
                    onChange={(e, value) => onChangeValue('ausbaunummer', value)}
                    editable
                  />
                    : <Value>{datenkarte.ausbaunummer}</Value>}
                  <Title>Fahrgestellnummer</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.fahrgestellnr}
                    node={Value}
                    onChange={(e, value) => onChangeValue('fahrgestellnr', value)}
                    editable
                  />
                    : <Value>{datenkarte.fahrgestellnr}</Value>}
                  <Title>Kilometerstand</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.kmstand}
                    node={Value}
                    onChange={(e, value) => onChangeValue('kmstand', value)}
                    editable
                  />
                    : <Value>{datenkarte.kmstand}</Value>}
                  <Title>Kennzeichen</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.kennzeichen}
                    node={Value}
                    onChange={(e, value) => onChangeValue('kennzeichen', value)}
                    editable
                  />
                    : <Value>{datenkarte.kennzeichen}</Value>}
                </>}
              {datenkarte.type === 'medizinprodukt' &&
                <>
                  <Title>Kundennummer</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.kdid}
                    node={Value}
                    onChange={(e, value) => onChangeValue('kdid', value)}
                    editable
                  />
                    : <Value>{datenkarte.kdid}</Value>}
                  <Title>Produkt</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.produkt}
                    node={Value}
                    onChange={(e, value) => onChangeValue('produkt', value)}
                    editable
                  />
                    : <Value>{datenkarte.produkt}</Value>}
                  <Title>Hersteller</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.hersteller}
                    node={Value}
                    onChange={(e, value) => onChangeValue('hersteller', value)}
                    editable
                  />
                    : <Value>{datenkarte.hersteller}</Value>}
                  <Title>Modell</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.model}
                    node={Value}
                    onChange={(e, value) => onChangeValue('model', value)}
                    editable
                  />
                    : <Value>{datenkarte.model}</Value>}
                  <Title>Baujahr</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.baujahr}
                    node={Value}
                    onChange={(e, value) => onChangeValue('baujahr', value)}
                    editable
                  />
                    : <Value>{datenkarte.baujahr}</Value>}
                  <Title>Seriennummer</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.seriennummer}
                    node={Value}
                    onChange={(e, value) => onChangeValue('seriennummer', value)}
                    editable
                  />
                    : <Value>{datenkarte.seriennummer}</Value>}
                  <Title>Standort</Title>
                  {edit ? <ContentEditable
                    html={datenkarte.standort}
                    node={Value}
                    onChange={(e, value) => onChangeValue('standort', value)}
                    editable
                  />
                    : <Value>{datenkarte.standort}</Value>}
                </>}
            </Box>

            {!!letztePruefung &&
              <>
                <Headline>Letzte Prüfung</Headline>
                <Box decorated={letztePruefung.status === 'positive' ? 'blazon' : 'blazonred'}>
                  <Title>Datum</Title>
                  <Value>{formatDate(new Date(letztePruefung.updatedAt), 'dd.MM.yyyy')}</Value>
                  <Title>Ergebnis</Title>
                  <Value>{letztePruefung.status === 'positive' ? 'In Ordnung' : 'Reparatur erforderlich'}</Value>
                  {/* <Title>Prüfer</Title>
                  <Value>{letztePruefung.user.personalnummer}, {letztePruefung.user.name}</Value> */}
                  <AbschlussberichtLink to={`${props.match.url}${props.match.url.slice(-1) === '/' ? '' : '/'}abschlussbericht/${letztePruefung.id}`}>Abschlussbericht einsehen</AbschlussberichtLink>
                </Box>
              </>}
            {!!medizinProdukte &&
              <>
                <Headline>Zugeordnete Medizinprodukte</Headline>
                {medizinProdukte.map(medizinprodukt => (
                  <Box key={medizinprodukt.id}>
                    <Title>Produkt</Title>
                    <Value>{medizinprodukt.produkt}</Value>
                    <Title>Hersteller</Title>
                    <Value>{medizinprodukt.hersteller}</Value>
                    <Title>Seriennummer</Title>
                    <Value>{medizinprodukt.seriennummer}</Value>
                  </Box>
                ))}
              </>}
          </Grid>
        </FixedGrid>}
    </>
  )
}

const FixedGrid = styled(Grid)`
  flex: 1;
  justify-content: center;
  max-width: 780px;
`

const Headline = styled.h1`
  padding-top: 30px;
  padding-bottom: 20px;
`

const Box = styled.div`
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 16px 0 #E0E2E4;
  padding: 20px;
  margin-bottom: 20px;
  ${props => props.decorated && css`
    background-image: url(${props.decorated === 'blazon' ? Blazon : BlazonRed});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 144px auto;
  `}
`
const LoginWrapper = styled.form`
  display: block;
  padding: 30px 22px 30px 22px;
  border-radius: 16px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 16px 0 #E0E2E4;
`

const AbschlussberichtLink = styled(Link)`
  font-style: italic;
  color: rgba(252,130,41,0.9);
  font-size: 24px;
  line-height: 34px;
  padding: 10px 0px;
  text-align: center;
  display: block;
`

export default Datenkarte
export { Footer, Abschlussbericht }
