import Session from './Session'
import Request from './Request'
import config from './Config'
const Api = () => {
  return {
    exec: async (url, params, noSession) => {
      const session = Session(config.prefix)
      params = session.append(params)
      const request = Request()
      const res = await request.exec(`${config.backend}${url}`, params)

      /**
       * Force Logout if jwt expired
       */
      if (res.error === 'jwt expired') {
        Session('see').destroy()
        window.location.reload()
      }

      return res
    },
    setConfig: ({ backend, prefix }) => {
      config = { backend, prefix }
    },
    buildUrl: (url) => {
      return `${config.backend}${url}`
    },
    execFileUpload: async (url, params, file) => {
      const session = Session(config.prefix)
      params = session.append(params)

      return new Promise((resolve, reject) => {
        const loaded = () => {
          return resolve(xhr.status)
        }
        const onError = (e) => {
          return e
        }
        const formData = new FormData()
        formData.append('file', file)
        const xhr = new XMLHttpRequest()
        xhr.addEventListener('error', onError, false)
        xhr.addEventListener('load', loaded, false)
        xhr.open('POST', `${config.backend}${url}`, true)
        xhr.send(formData)
      })
    }
  }
}

export default Api
