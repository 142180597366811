import React, { useState } from 'react'
import Button from '../common/Button'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import IconWarning from '../../assets/images/warning.svg'
import Notification from '../common/Notification'
import Spacer from '../common/Spacer'
import translateErrorMessage from '../common/translate/errorMessage'

const Destroy = ({ onDestroy, onCancel }) => {
  const [errorMessage, setErrorMessage] = useState(null)

  const destroy = async () => {
    await setErrorMessage(null)
    try {
      onDestroy()
      // remove logged in user name and personalnummer
      // Clean up old storage values
      if (window.localStorage.getItem('see-user-name-personalnummer') !== undefined) {
        window.localStorage.removeItem('see-user-name-personalnummer')
      }
      window.localStorage.removeItem('see-user-name')
      window.localStorage.removeItem('see-user-personalnummer')
      onCancel()
    } catch (err) {
      await setErrorMessage(err.message)
    }
  }

  return (
    <DestroyModal item xs={12}>
      {errorMessage &&
        <>
          <Notification type='negative' close={() => setErrorMessage(false)}>
            {translateErrorMessage(errorMessage)}
          </Notification>
          <Spacer vertical='20px' />
        </>}
      <SmallText>
        Möchten Sie sich abmelden? <br />
        Nach Abmeldung müssen Sie sich erneut anmelden.
      </SmallText>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Button tabIndex='3' fullWidth primary onClick={onCancel}>Nein</Button>
        </Grid>
        <Grid item xs={6}>
          <Button tabIndex='2' fullWidth primary onClick={destroy} isActive>Ja</Button>
        </Grid>
      </Grid>
    </DestroyModal>
  )
}

const DestroyModal = styled(Grid)`
  background-image: url(${IconWarning});
  background-position: 50% 15%;
  background-repeat: no-repeat;
  background-size: 66px 57px;
`
const SmallText = styled.p`
  font-size: 14px;
  margin-bottom: 45px;
`

export default Destroy
