import React from 'react'
import ListItem from '../common/Listitem'

const List = ({ users, onDestroy, onSelect }) => {
  return (
    <>
      {users.map(user => {
        return (
          <ListItem
            key={user.id}
            active={user.active}
            title={`${user.name}, ${user.personalnummer}`}
            stateText={user.active ? 'Aktiv' : 'Inaktiv'}
            action='delete'
            data={user}
            onSelect={onSelect}
            onDestroy={onDestroy}
          />
        )
      })}
    </>
  )
}

export default List
