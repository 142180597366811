import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg width={props.width || 16} height={props.height || 12} viewBox='0 0 16 12' {...props}>
      <path
        d='M7.273 0h2.909v2h-2.91V0zm0 12v-2H16v2H7.273zm-3.637 0l3.637-4.353H4.545V0H2.727v7.647H0L3.636 12zm9.455-5V5H7.273v2h5.818z'
        fill='#CDCDCD'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default SvgComponent
