const builderInputTypes = [
  {
    label: 'Prüfaufgabentextfeld',
    value: 'inspection',
    alternativeTitle: 'Prüfaufgabentextfeld'
  },
  {
    label: 'Wartungsaufgabe',
    value: 'maintenance',
    alternativeTitle: 'Wartungsaufgabe'
  },
  {
    label: 'Messaufgabe',
    value: 'measuring',
    alternativeTitle: 'Messaufgabe'
  }
]

export default builderInputTypes
