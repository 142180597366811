import '../assets/reset.css'
import '../assets/main.css'
import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { DefaultLayoutPublicRoute, DefaultLayoutPrivateRoute, ResponsiveLayoutPublicRoute } from './LayoutRoutes'
import Login from './Login/Login'
import NewUserLogin from './Login/LoginNewUser'
import UserManagement from './UserManagement'
import Audits from './Audits'
import Vehicles from './Vehicles'
import Products from './Products'
import TestReportTemplates, { Edit as EditTemplate } from './TestReportTemplates'
import AppDownload from './AppDownload'
import Datenkarte, { Abschlussbericht } from './Datenkarte' // eslint-disable-line

import NotFound from './NotFound'

const App = () => {
  return (
    <Router basename={window.location.pathname + '#'}>
      <Switch>
        <Route
          path='/' exact render={() => (
            <Redirect to='/pruefprotokollvorlagen' />
          )}
        />
        <DefaultLayoutPrivateRoute path='/nutzerverwaltung' component={UserManagement} />
        <DefaultLayoutPrivateRoute exact path='/pruefungen' component={Audits} />
        <DefaultLayoutPrivateRoute exact path='/fahrzeuge' component={Vehicles} />
        <DefaultLayoutPrivateRoute exact path='/medicalproducts' component={Products} />
        <DefaultLayoutPrivateRoute exact path='/pruefprotokollvorlagen' component={TestReportTemplates} />
        <DefaultLayoutPrivateRoute path='/app' component={AppDownload} />
        <DefaultLayoutPrivateRoute path='/pruefprotokollvorlagen/neu' component={EditTemplate} />
        <DefaultLayoutPrivateRoute path='/pruefprotokollvorlagen/id/:id' component={EditTemplate} />
        <DefaultLayoutPrivateRoute path='/login/neu' component={NewUserLogin} />
        <DefaultLayoutPublicRoute path='/login' component={Login} />
        <DefaultLayoutPublicRoute path='/not-found' component={NotFound} />
        {/* Public View for Datenkarte */}
        <ResponsiveLayoutPublicRoute exact path='/datenkarte/:id' component={Datenkarte} />
        <ResponsiveLayoutPublicRoute exact path='/datenkarte/:id/abschlussbericht/:berichtid' component={Abschlussbericht} />
      </Switch>
    </Router>
  )
}

export default App
