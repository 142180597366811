import React from 'react'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import Theme from './Theme'

const StyledSelect = styled(ReactSelect)`
width: 100%;  
color: #393939;
  > div {
    border-radius: 4px;
    svg {
      fill: ${Theme.color6};
    }
  }
  span {
    display: none;
  }
  .selectbox__control {
    min-height: 40px;
    outline: none;
    border: 1px solid ${Theme.color6};
    &.selectbox__control--is-focused {
      box-shadow: none;
      border: 1px solid ${Theme.color4};
    }
  }
  .selectbox__value-container {
  }
  .selectbox__indicators {
  }
  .selectbox__menu {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.5);
    .selectbox__option {
      &.selectbox__option--is-focused {
        background-color: #eeeeee;
      }
      &.selectbox__option--is-selected {
        background-color: ${Theme.color4};
      }
    }
  }
`

const Select = (props) => {
  return (
    <StyledSelect placeholder='Auswahl...' classNamePrefix='selectbox' className='selectbox-container' {...props} />
  )
}

export default Select
