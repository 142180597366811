import React, { useState } from 'react'
import styled from 'styled-components'
import TextInput from '../common/TextInput'
import Button from '../common/Button'
import Spacer from '../common/Spacer'
import User from '../common/ApiHandler/User'
import Notification from '../common/Notification'
import Grid from '@material-ui/core/Grid'
import translateErrorMessage from '../common/translate/errorMessage'

const Login = (props) => {
  const [user, setUser] = useState({
    username: '',
    password: ''
  })
  const [notification, setNotification] = useState()

  const onNotification = async (message, type) => {
    setNotification(
      <Notification close={close} type={type}>{message}</Notification>
    )
  }

  const close = async () => {
    await setNotification(null)
  }

  const handleChange = (event, field) => {
    setUser({
      ...user,
      [field]: event.target.value
    })
  }

  const submit = async (e) => {
    e.preventDefault()
    User({ personalnummer: user.username, password: user.password }).login()
      .then(session => {
        if (session.error) {
          throw session.error
        } else if (session.needspassword) {
          props.history.push('/login/neu')
        } else {
          // Add login name + personalnummer to local storage, remove it on logout
          window.localStorage.setItem('see-user-name', session.user.name)
          window.localStorage.setItem('see-user-personalnummer', session.user.personalnummer)
          props.history.push('/pruefprotokollvorlagen')
        }
      })
      .catch(err => {
        onNotification(translateErrorMessage(err), 'negative')
      })
  }

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
    >
      {notification && (
        <Grid item xs={12} sm={8} md={5} lg={4}>{notification}</Grid>
      )}
      <Grid item xs={12}>&nbsp;</Grid>
      <Grid item xs={8} sm={6} md={5} lg={4}>
        <LoginWrapper onSubmit={(e) => submit(e)}>
          <Spacer vertical='10px' />
          <TextInput labelPosition='top' onChange={(e) => handleChange(e, 'username')} label='Personalnummer' />
          <TextInput labelPosition='top' onChange={(e) => handleChange(e, 'password')} label='Passwort' type='password' placeholder='***********' />
          <Spacer vertical='20px' />
          <Button fullWidth primary isActive>Einloggen</Button>
        </LoginWrapper>
      </Grid>
    </Grid>
  )
}

const LoginWrapper = styled.form`
  display: block;
  padding: 30px 22px 30px 22px;
  border-radius: 16px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 16px 0 #E0E2E4;
`

export default Login
