import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'
import Logo from '../../assets/images/logo.svg'
import IconDown from '../../assets/images/down.svg'
import Users from '../common/ApiHandler/User'
import { useSession } from './ApiHandler/Session'
import HeaderSessionDestroy from './HeaderSessionDestroy'
import { version as APPLICATIONVERSION } from '../../../package.json'

import Modal from './Modal'

const StyledHeader = styled.header`
  height: 58px;
  padding: 0px 20px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: end;
  box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);
  background-color: #FC8E3E;
  background: linear-gradient(90deg, #FC8229 0%, #EB5B3B 100%);
  z-index: 100;
`

const HeaderSpacing = styled.div`
  height: 58px;
  padding-bottom: 30px;
`

const Container = styled.div`
  width: 1440px;
  margin: 0 auto;
  position: relative;
`

const HeaderContainer = styled.div`
  max-width: 1540px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Navbar = styled.nav`
  display: flex;
  margin-left: 12px;
`

const Navitem = styled(NavLink)`
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  padding: 0px 12px;
  display: block;
  color: #FFF;
  text-decoration: none;
  font-weight: 600;
  margin-left: 18px;
  &.active {
    background-color: #FFF;
    border-radius: 6px;
    color: #EB5B3B;
  }
`

const LogoWrap = styled.div`
  padding: 10px 0px 10px 20px;
`

const LoggedInInfo = styled.div`
  margin-left: auto;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
`

const UserInfoButton = styled.button`
  border: none;
  background: transparent;
  margin-left: 20px;
`

const LogoutImg = styled.img`
  transform: rotate(270deg);
`

const VersionText = styled.span`
  position: absolute;
  right: 5px;
  bottom: 0px;
  font-size: 10px;
  color: white;
  opacity: 0.8;
  text-align: right;
`

const Header = (props) => {
  const [displayLogoutConfirmation, setDisplayLogoutConfirmation] = useState(false)
  const [userText, setUserText] = useState(false)

  const location = useLocation()

  const logout = () => {
    Users({}).logout()
  }

  const { session } = useSession()
  const isUpdatedUser = !!window.localStorage.getItem('see-user-personalnummer')

  const isViewingDatenkarte = window.location.hash.includes('datenkarte')

  useEffect(() => {
    const usertxt = `${window.localStorage.getItem('see-user-personalnummer')}, ${window.localStorage.getItem('see-user-name')}`
    setUserText(usertxt)
  }, [location.pathname])

  return (
    <>
      <StyledHeader id='header'>
        {displayLogoutConfirmation &&
          <Modal isOpen>
            <HeaderSessionDestroy onCancel={() => setDisplayLogoutConfirmation(false)} onDestroy={logout} />
          </Modal>}
        <Container id='styled-header-container'>
          {(!session || !isUpdatedUser || isViewingDatenkarte) &&
            <LogoWrap style={{ paddingLeft: '0' }}><img style={{ margin: '0 auto', display: 'block' }} alt='Logo' width='172px' src={Logo} /></LogoWrap>}
          {session && isUpdatedUser && !isViewingDatenkarte &&
            <HeaderContainer>
              <LogoWrap><img alt='Logo' width='172px' src={Logo} /></LogoWrap>
              <Navbar>
                <Navitem key='pruefprotokollvorlagen' to='/pruefprotokollvorlagen'>Prüfprotokollvorlagen</Navitem>
                <Navitem key='nutzerverwaltung' to='/nutzerverwaltung'>Nutzerverwaltung</Navitem>
                <Navitem key='pruefungen' to='/pruefungen'>Prüfungen</Navitem>
                <Navitem key='fahrzeuge' to='/fahrzeuge'>Fahrzeuge</Navitem>
                <Navitem key='medicalproducts' to='/medicalproducts'>Produkte</Navitem>
                <Navitem key='app' to='/app'>App</Navitem>
              </Navbar>
              <LoggedInInfo>
                {userText &&
                  <>{userText}</>}
                {!userText &&
                  <>Logout</>}
                <UserInfoButton><LogoutImg onClick={() => setDisplayLogoutConfirmation(true)} width='12px' src={IconDown} alt='Mehr anzeigen' /></UserInfoButton>
              </LoggedInInfo>
            </HeaderContainer>}
        </Container>
        <VersionText>v{APPLICATIONVERSION.split('-')[0]}</VersionText>
      </StyledHeader>
      <HeaderSpacing id='header-spacing' />
    </>
  )
}

export default Header
