import React, { useState } from 'react'
import Users from '../common/ApiHandler/User'
import Button from '../common/Button'
import TextInput from '../common/TextInput'
import Grid from '@material-ui/core/Grid'
import translateErrorMessage from '../common/translate/errorMessage'

const Create = ({ onCreate, onCancel, onNotification, onModalNotification }) => {
  const [userData, setUserData] = useState({
    name: '',
    personalnummer: ''
  })

  const handleUserDataChange = (field, value) => {
    setUserData({ ...userData, [field]: value })
  }

  const createUser = async () => {
    try {
      const result = await Users({}).create(userData)
      onCancel()
      onCreate()
      // Notification outside positive
      onNotification(`Der Nutzer '${result.name}, ${result.personalnummer}' wurde erfolgreich erstellt.`, 'positive')
    } catch (err) {
      // Notification inside negative
      await onModalNotification(translateErrorMessage(err.message), 'negative')
    }
  }

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <TextInput tabIndex='0' labelPosition='top' onChange={(e) => handleUserDataChange('name', e.target.value)} label='Name' />
          <Button tabIndex='3' fullWidth primary onClick={onCancel}>Abbrechen</Button>
        </Grid>
        <Grid item xs={6}>
          <TextInput tabIndex='1' labelPosition='top' onChange={(e) => handleUserDataChange('personalnummer', e.target.value)} label='Personalnummer' />
          <Button tabIndex='2' fullWidth primary onClick={createUser} isActive>Erstellen</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default Create
