import React from 'react'
import styled from 'styled-components'
import Theme from './Theme'

const WrapperLabel = styled.label`
`

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  border: 1px solid ${Theme.color6};
  height: 20px;
  border-radius: 4px;
  background-color: ${Theme.color5};
  box-shadow: inset -1px 1px 1px 0 rgba(0,0,0,0.3);
  opacity: 0.8;
`

const CheckboxLabel = styled.span`
  margin-left: 8px;
  color: ${Theme.color6};
`

const DisabledCheckbox = ({ title, value, isChecked, onChange, ...props }) => {
  return (
    <WrapperLabel>
      <CheckboxContainer>
        <HiddenCheckbox />
        <StyledCheckbox />
      </CheckboxContainer>
      <CheckboxLabel>{title}</CheckboxLabel>
    </WrapperLabel>
  )
}

export default DisabledCheckbox
