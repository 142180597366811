import React from 'react'
import styled from 'styled-components'

import iconCar from '../../assets/images/fahrzeug.png'
import iconUtil from '../../assets/images/medizinprodukt.png'

import IconDelete from '../../assets/svgcomponents/Delete'
import IconDuplicate from '../../assets/svgcomponents/Duplicate'

import Theme from './Theme'

const ListItemWrapper = styled.div`
  height: 80px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #DDDDDD;
  box-sizing: border-box;
  background: ${Theme.color5};
  padding: 10px 20px;
  display: flex;
  h4 {
    padding: 0;
    margin: 0;
  }
`

const StateWrapper = styled.div`
  line-height: 19px;
  height: 19px;
  position: absolute;
  bottom: 15px;
  left: ${props => props.icon ? '100px' : '20px'};
`

const StateCircle = styled.span`
  height: 14px;
  width: 14px;
  border-radius: 7px;
  background-color: ${props => props.active ? Theme.color4 : Theme.color3};
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
`

const StateText = styled.span`
  font-size: 14px;
  color: #333;
`

const AdditionalInfo = styled.span`
  font-style: italic;
  font-size: 12px;
  text-align: right;
  position: absolute;
  right: 100px;
  bottom: 15px;
`

const Icon = styled.img`
  height: 53px;
  width: 53px;
  display: inline-block;
  margin-right: 25px;
`

const ActionButton = styled.button`
  display: block;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  height: 80px;
  width: 80px;
  svg {
    fill: #CDCDCD;
    &:hover {
      path {
        fill: #FC8229;
      }
    }
  }
`

const ActionWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 0px;
  z-index: 2;
`

const ListItemActionWrapper = styled.div`
  width: calc(100% - 80px);
  left: 0px;
  top: 0px;
  height: 80px;
  position: absolute;
  cursor: pointer;
`

const ListItem = ({ title, id, stateText, onDuplicate, onDestroy, onSelect, data, ...props }) => {
  const select = () => {
    onSelect(data)
  }

  const destroy = () => {
    onDestroy(data)
  }

  const duplicate = () => {
    onDuplicate(data)
  }

  return (
    <ListItemWrapper>
      <ListItemActionWrapper className='actionWrapper' onClick={select} />
      {props.typ && props.typ === 'medizinprodukt' &&
        <Icon src={iconUtil} />}
      {props.typ && props.typ === 'fahrzeug' &&
        <Icon src={iconCar} />}
      <h4>{title}</h4>
      <StateWrapper icon={!!props.typ}>
        <StateCircle active={props.active} />
        {stateText &&
          <StateText>{stateText}</StateText>}
      </StateWrapper>
      {props.additionalInfo && props.additionalInfo !== '' &&
        <AdditionalInfo>{props.additionalInfo}</AdditionalInfo>}
      {props.action &&
        <ActionWrapper>
          {props.action === 'delete' &&
            <ActionButton onClick={destroy}><IconDelete /></ActionButton>}
          {props.action === 'duplicate' &&
            <ActionButton onClick={duplicate}><IconDuplicate /></ActionButton>}
        </ActionWrapper>}
    </ListItemWrapper>
  )
}

export default ListItem
