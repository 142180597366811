import React from 'react'
import styled from 'styled-components'
import Checkmark from '../../assets/svgcomponents/checkmark'
import X from '../../assets/svgcomponents/x'
import Button from '../common/Button'

const RootContainer = styled.div`
  width: 100%;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  margin-top: 3px;
`

const Text = styled.span`
  font-family: 'Open Sans';
  font-size: 18px;
  color: #333;
  margin-left: 15px;
  margin-bottom: 3px;
  flex: 1;
`

const BilledButton = styled(Button)`
  margin-top: 10px;
  margin-bottom: 10px;
  transition: color 300ms;
`

const Protocols = ({ protocols, billed, onClick }) => {
  return (
    <RootContainer>
      {protocols.map(protocol => {
        let status = <X color='#333' width='15px' height='15px' />
        if (protocol.status === 'positive') {
          status = <Checkmark color='rgba(252,130,41,0.9)' width='15px' height='15px' />
        }
        return (
          <RowContainer>
            {status}
            <Text>{protocol.title}</Text>
          </RowContainer>
        )
      })}
      <BilledButton
        onClick={onClick}
        primary={!billed}
        isActive={!billed}
      >
        {!!billed ? 'Als nicht abgerechnet markieren' : 'Als abgerechnet markieren'}
      </BilledButton>
    </RootContainer>
  )
}

export default Protocols
