import React from 'react'
import ListItem from '../common/Listitem'
import translateDatetime from '../common/translate/datetime'

const TemplateList = ({ data, onSelect, onDuplicate }) => {
  return (
    <>
      {Array.isArray(data) && data.map((template, index) => {
        return (
          <ListItem
            key={`template-${index}`}
            data={template}
            typ={template.typ}
            active={template.published}
            title={template.title}
            stateText={template.published ? 'veröffentlicht ' : 'nicht öffentlich'}
            action='duplicate'
            additionalInfo={`Zuletzt bearbeitet am ${translateDatetime(template.updatedAt)}`}
            onSelect={onSelect}
            onDuplicate={onDuplicate}
          />
        )
      })}
    </>
  )
}

export default TemplateList
