import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg width={props.width || "29px"} height={props.height || "27px"} viewBox="0 0 29 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="05-Abschlussbericht" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="F-00-Abschlussbericht" transform="translate(-149.000000, -1618.000000)" stroke={props.color || "#FFFFFF"} stroke-width="4">
          <g id="Group" transform="translate(133.000000, 1600.000000)">
            <g id="Outton/check/active">
              <polyline id="haken" points="18 29.9048563 26.5383531 41 43 20"></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
