import * as React from 'react'

const Duplicate = (props) => {
  return (
    <svg width={props.width || '20'} height={props.height || '20'} viewBox='0 0 20 20' {...props}>
      <path
        d='M2 4v14h14v2H2c-1.1 0-2-.9-2-2V4h2zm16-4c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V2c0-1.1.9-2 2-2h12zm-5.143 8.857H18V7.143h-5.143V2h-1.714v5.143H6v1.714h5.143V14h1.714V8.857z'
        fill={props.fill || '#CDCDCD'}
        fillRule='evenodd'
      />
    </svg>
  )
}

export default Duplicate
