import React, { useEffect, useState } from 'react'
import { User } from '../common/ApiHandler/User'
import Button from '../common/Button'
import Grid from '@material-ui/core/Grid'
import RadioButtons from '../common/RadioButton'
import CopyField from '../common/CopyField'
import Spacer from '../common/Spacer'
import styled from 'styled-components'
import IconReload from '../../assets/images/reload.svg'
import translateErrorMessage from '../common/translate/errorMessage'
import Modal from '../common/Modal'
import ConfirmOTPGenerate from './ConfirmOTPGenerate'

const Edit = ({ user, onCancel, onSave, onNotification, onModalNotification }) => {
  const [userData, setUserData] = useState({
    personalnummer: '',
    name: ''
  })

  const [confirmOTPGenerate, setConfirmOTPGenerate] = useState(false)

  useEffect(() => {
    if (userData === user) {
      return
    }
    setUserData(user)
    // eslint-disable-next-line
  }, [user])

  const handleUserStatusChange = (value) => {
    user.active = value
  }

  const updateUser = async () => {
    try {
      await user.save()
      onCancel()
      onSave()
      // Notification outside positive
      onNotification(`Der Nutzer '${user.name}, ${user.personalnummer}' wurde erfolgreich editiert.`, 'positive')
    } catch (err) {
      // Notification inside negative
      await onModalNotification(translateErrorMessage(err.message), 'negative')
    }
  }

  const otpGenerate = async () => {
    try {
      const user = User(userData)
      const updatedUser = await user.otpGenerate()
      setUserData(updatedUser.user)
    } catch (err) {
      await onModalNotification(translateErrorMessage(err.message), 'negative')
    }
  }

  return (
    <>
      {confirmOTPGenerate &&
        <Modal isOpen>
          <ConfirmOTPGenerate user={user} onCancel={() => setConfirmOTPGenerate(false)} onGenerate={otpGenerate} onModalNotification={onModalNotification} />
        </Modal>}
      <div style={{ display: 'flex' }}>
        <div style={{ width: '40%' }}>
          <span className='textlabel'>Name, Personalnummer:</span>
          {userData.name}, {userData.personalnummer}
          <br />
        </div>
        <div style={{ width: '40%' }}>
          <span className='textlabel'>Status:</span>
          <RadioButtons
            defaultValue={userData.active}
            onChange={handleUserStatusChange}
            options={[
              {
                label: 'Aktiv',
                value: true
              },
              {
                label: 'Inaktiv',
                value: false
              }]}
          />
        </div>
      </div>
      <Spacer vertical='30px' />
      <div style={{ display: 'flex' }}>
        <CopyField style={{ width: '93%' }} value={userData.otp} />
        <ReloadButton onClick={() => setConfirmOTPGenerate(true)}><img src={IconReload} width='16px' alt='Neu Laden' /></ReloadButton>
      </div>
      <Spacer vertical='30px' />
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Button tabIndex='3' fullWidth primary onClick={onCancel}>Abbrechen</Button>
        </Grid>
        <Grid item xs={6}>
          <Button tabIndex='2' fullWidth primary onClick={updateUser} isActive>Speichern</Button>
        </Grid>
      </Grid>
    </>
  )
}

const ReloadButton = styled.button`
  background: transparent;
  border: none;
  margin-left: 20px;
`

export default Edit
