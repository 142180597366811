import styled from 'styled-components'

const Title = styled.div`
  color: #999;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Open Sans Light';
  &:after {
    content: ':';
  }
`

const Value = styled.p`
  font-size: 24px;
  color: #333;
  padding-left: 10px;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e6e6e6;
`

export { Title, Value }
