import React from 'react'
import styled from 'styled-components'
import Header from './common/Header'
import Grid from '@material-ui/core/Grid'
import { useSession } from './common/ApiHandler/Session'
import ScrollToTop from './common/ScrollToTop'
import { Footer } from './Datenkarte'
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

const DefaultLayout = ({ children }) => {
  return (
    <>
      <ScrollToTop />
      <StyledApp>
        <Header />
        <FixedGrid
          container
          spacing={3}
          justify='center'
        >
          <Grid item xs={11}>
            {children}
          </Grid>
        </FixedGrid>
      </StyledApp>
    </>
  )
}

const ResponsiveLayout = ({ children }) => {
  return (
    <>
      <ScrollToTop />
      <StyledMobileApp>
        <Header />
        {children}
        <Footer />
      </StyledMobileApp>
    </>
  )
}

const DefaultLayoutPrivateRoute = ({ component: Component, ...rest }) => {
  const { session } = useSession()
  return (
    <Route
      {...rest} render={(props) => (
        session ? <DefaultLayout><Component {...props} /></DefaultLayout> : session && session.needspassword ? <Redirect to='/login/neu' /> : <Redirect to='/login' />
      )}
    />
  )
}

const DefaultLayoutPublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest} render={(props) => (
        <DefaultLayout><Component {...props} /></DefaultLayout>
      )}
    />
  )
}

const ResponsiveLayoutPublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest} render={(props) => (
        <ResponsiveLayout><Component {...props} /></ResponsiveLayout>
      )}
    />
  )
}

const StyledApp = styled.div`
  min-height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
`

const StyledMobileApp = styled(StyledApp)`
  min-height: 100%;
  padding-bottom: 0px;
`

const FixedGrid = styled(Grid)`
  flex: 1;
  justify-content: center;
  max-width: 1540px;
`

export { DefaultLayoutPrivateRoute, DefaultLayoutPublicRoute, ResponsiveLayoutPublicRoute }
