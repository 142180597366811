import React from 'react'
import Theme from './Theme.js'
import _Breadcrumbs from '@material-ui/core/Breadcrumbs'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'

const Breadcrumbs = ({ items, separator = '>', ...restProps }) => {
  return (
    <Wrapper container direction='row'>
      <List separator='>' aria-label='breadcrumb'>
        {Array.isArray(items) && items.map((item, index) => {
          const isLastItem = index === (items.length - 1)
          return (
            <Item key={index}>
              {item.path &&
                <Link key={index} to={item.path}>
                  {item.title}
                </Link>}
              {!item.path &&
                <span key={index} style={isLastItem ? { textAlign: 'center', paddingLeft: '15px', paddingRight: '15px', fontFamily: 'Open Sans Bold' } : {}}>
                  {item.title}
                </span>}
              {isLastItem &&
                <ActiveIndicator />}
            </Item>
          )
        })}
      </List>
    </Wrapper>
  )
}

const Item = styled.div`
  position: relative;
  height: 42px;
  margin-right: 20px;
  a, span {
    padding-top: 12px;
    text-decoration: none;
    color: ${Theme.color1};
    font-size: 14px;
    display: block;
  }
`

const ActiveIndicator = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0px;
  width: 100%;
  border-bottom: 1px solid ${Theme.color2};
`

const Wrapper = styled(Grid)`
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
  `

const List = styled(_Breadcrumbs)`
  height:42px;
  li.MuiBreadcrumbs-separator {
    padding-top: 4px;
    font-size: 14px;
    color: ${Theme.color1}
  }
`

export default Breadcrumbs
