import React, { useEffect, useState, forwardRef } from 'react'
import Audits, { Audit } from '../common/ApiHandler/Audit'
import styled from 'styled-components'
import { format as formatDate } from 'date-fns'
import MaterialTable from '@material-table/core'

import CarImage from '../../assets/images/fahrzeug.png'
import MedicalProductImage from '../../assets/images/medizinprodukt.png'
import Checkmark from '../../assets/svgcomponents/checkmark'
import X from '../../assets/svgcomponents/x'
import Protocols from './protocols'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const TypeImage = styled.img`
  height: 35px;
  width: 35px;
`

const AuditsManagement = () => {
  const [isActive, setActive] = React.useState(false)
  const [loading, setLoading] = useState(true)
  const [audits, setAudits] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    if (!isActive) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive])

  const loadData = async () => {
    setLoading(true)
    const audits = Audits({})
    const loadedAudits = await audits.findAll({ status: 'positive,negative' })
    setAudits(loadedAudits.map(audit => {
      audit.pruefdatum = formatDate(new Date(audit.updatedAt), 'dd.MM.yyyy')
      return audit
    }))
    setColumns([
      {
        title: 'Status',
        field: 'status',
        lookup: {
          positive: 'Bestanden',
          negative: 'Nicht bestanden'
        },
        render: rowData => {
          if (rowData.status === 'positive') {
            return <Checkmark color='rgba(252,130,41,0.9)' width='20px' height='20px' />
          }
          return <X color='#333' width='20px' height='20px' />
        }
      },
      {
        title: 'Abgerechnet',
        field: 'billed',
        lookup: {
          true: 'Abgerechnet',
          false: 'Nicht abgerechnet'
        },
        render: rowData => {
          if (rowData.billed) {
            return <Checkmark color='rgba(252,130,41,0.9)' width='20px' height='20px' />
          }
          return <X color='#333' width='20px' height='20px' />
        }
      },
      {
        title: 'Typ',
        field: 'datenkarte.type',
        lookup: {
          fahrzeug: 'Fahrzeug',
          medizinprodukt: 'Medizinprodukt'
        },
        render: rowData => (<TypeImage alt={rowData.datenkarte.type} src={rowData.datenkarte.type === 'fahrzeug' ? CarImage : MedicalProductImage} />)
      },
      { title: 'Prüfdatum', field: 'pruefdatum' },
      { title: 'Kundennummer', field: 'datenkarte.kdid' },
      { title: 'Fahrgestellnummer', field: 'datenkarte.fahrgestellnr' },
      { title: 'Kennzeichen', field: 'datenkarte.kennzeichen' },
      { title: 'Fahrzeugart', field: 'datenkarte.fahrzeugart' },
      { title: 'Hersteller', field: 'datenkarte.hersteller' },
      { title: 'Modell', field: 'datenkarte.model' }
    ])
    setLoading(false)
    setActive(true)
  }

  const switchBillable = async (id) => {
    const index = audits.findIndex(audit => audit.id === id)
    const audit = Audit({ id, billed: !audits[index].billed })
    await audit.save()
    audits[index].billed = !audits[index].billed
    const newAudits = [...audits]
    setAudits(newAudits)
  }

  return (
    <React.Fragment>
      {loading && <p>Daten werden geladen</p>}
      {!loading &&
        <MaterialTable
          icons={tableIcons}
          options={{
            filtering: true,
            search: false,
            pageSizeOptions: [10, 20, 100],
            pageSize: 10,
            rowStyle: rowData => ({
              backgroundColor: rowData.tableData.id % 2 === 0 ? '#F2F2F2' : '#FFF'
            })
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} von {count}',
              labelRowsPerPage: 'Zeilen pro Seite:',
              labelRowsSelect: 'Zeilen'
            }
          }}
          columns={columns}
          data={audits}
          detailPanel={rowData => (
            <Protocols
              protocols={rowData.pruefprotokolls}
              billed={rowData.billed}
              onClick={() => switchBillable(rowData.id)}
            />
          )}
          title="Prüfungen"
        />
      }
    </React.Fragment>
  )
}

export default AuditsManagement
