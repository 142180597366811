import React, { useEffect, useState, forwardRef } from 'react'
import Datenkarten from '../common/ApiHandler/Datenkarten'
import MaterialTable from '@material-table/core'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const DatenkartenLink = styled(Link)`
  font-style: italic;
  color: rgba(252,130,41,0.9);
`

const VehiclesManagement = () => {
  const [isActive, setActive] = React.useState(false)
  const [loading, setLoading] = useState(true)
  const [vehicles, setVehicles] = useState([])

  useEffect(() => {
    if (!isActive) {
      loadData()
    }
  }, [isActive])

  const loadData = async () => {
    setLoading(true)
    const loadedVehicles = await Datenkarten.findAll({ type: 'fahrzeug' })
    setVehicles(loadedVehicles)
    setLoading(false)
    setActive(true)
  }

  return (
    <>
      {loading && <p>Daten werden geladen</p>}
      {!loading &&
        <MaterialTable
          icons={tableIcons}
          options={{
            filtering: true,
            search: false,
            pageSizeOptions: [10, 20, 100],
            pageSize: 10,
            rowStyle: rowData => ({
              backgroundColor: rowData.tableData.id % 2 === 0 ? '#F2F2F2' : '#FFF'
            })
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} von {count}',
              labelRowsPerPage: 'Zeilen pro Seite:',
              labelRowsSelect: 'Zeilen'
            }
          }}
          columns={[
            {
              title: 'Code',
              field: 'qrid',
              render: rowData => (<DatenkartenLink to={`/datenkarte/${rowData.qrid}`}>{rowData.qrid}</DatenkartenLink>)
            },
            { title: 'Kundennummer', field: 'kdid' },
            { title: 'Fahrgestellnummer', field: 'fahrgestellnr' },
            { title: 'Kennzeichen', field: 'kennzeichen' },
            { title: 'Fahrzeugart', field: 'fahrzeugart' },
            { title: 'Hersteller', field: 'hersteller' },
            { title: 'Modell', field: 'model' },
            { title: 'Baujahr', field: 'baujahr' },
            { title: 'Aufbau', field: 'aufbau' },
            { title: 'Ausbauer', field: 'ausbauer' },
            { title: 'Ausbaunummer', field: 'ausbaunummer' }
          ]}
          data={vehicles}
          title='Fahrzeuge'
        />}
    </>
  )
}

export default VehiclesManagement
