const translateDatetime = (datetime) => {
  const date = new Date(Date.parse(datetime))
  const day = getMinTwoDigits(date.getDate())
  const month = getMinTwoDigits(date.getMonth() + 1)
  const minutes = getMinTwoDigits(date.getMinutes())
  const hours = getMinTwoDigits(date.getHours())
  return `${day}.${month}.${date.getFullYear()} um ${hours}:${minutes}`
}

const getMinTwoDigits = (digits) => {
  return digits < 10 ? `0${digits}` : digits
}

export default translateDatetime
