import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

const LoadingIndicator = () => {
  return (
    <Progress style={{ margin: '20px auto 0 auto', display: 'block' }} />
  )
}

const Progress = styled(CircularProgress)`

`

export default LoadingIndicator
