import Api from './Api'
import Session from './Session'

const api = Api()

const User = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    save: async function () {
      const res = await api.exec(`/users/${id}`,
        {
          method: 'PUT',
          body: JSON.stringify(this)
        })
      if (res.error) {
        throw new Error(res.error)
      }
      return User(res)
    },
    destroy: async () => {
      const res = await api.exec(`/users/${id}`,
        {
          method: 'DELETE'
        })
      if (res.error) {
        throw new Error(res.error)
      }
      return res
    },
    otpGenerate: async function () {
      const res = await api.exec(`/user/${encodeURIComponent(id)}/otp/generate`,
        {
          method: 'POST'
        })
      if (res.error) {
        throw new Error(res.error)
      }
      return User(res)
    }
  }
}

const Users = ({ ...restProps }) => {
  return {
    ...restProps,
    create: async (user) => {
      const res = await api.exec('/users', {
        method: 'POST',
        body: JSON.stringify(user)
      })
      if (res.error) {
        throw new Error(res.error)
      }
      return User(res.user)
    },
    findAll: async (params) => {
      const res = await api.exec('/users', {
        method: 'GET',
        body: JSON.stringify(params)
      })
      if (res.error) {
        return res.error
      }
      if (res.users.length < 1) {
        return []
      }
      return res.users.map(user => User(user))
    },
    findOne: async (id) => {
      const res = await api.exec(`/users/${encodeURIComponent(id)}`, {
        method: 'GET'
      })
      if (res.error) {
        throw new Error(res.error)
      }
      return User(res.user)
    },
    login: async () => {
      const res = await api.exec('/user/authorize', {
        method: 'POST',
        body: JSON.stringify(restProps)
      }, true)
      if (res.error) {
        return res
      }
      Session().start(res)
      return User(res)
    },
    logout: async () => {
      Session().destroy()
      return true
    },
    setPassword: async () => {
      const res = await api.exec('/user/password', {
        method: 'PUT',
        body: JSON.stringify(restProps)
      }, true)
      if (res.error) {
        return res
      }
      Session().start(res)
      return User(res)
    }
  }
}

export { User }
export default Users
