import React from 'react'
import styled from 'styled-components'
import {format as formatDate} from 'date-fns'
import {app as APPINFO} from '../../../package.json'
import PageHeader from '../common/PageHeader'
import ImportedQRCode from 'qrcode.react'


const AppDownload = (props) => {
  const manifestUrl = `itms-services://?action=download-manifest&url=${window.origin}/manifest.plist`

  return (
    <React.Fragment>
      <PageHeader>
        <h1>App download</h1>
      </PageHeader>
      <Container>
        <span>Scannen Sie den QR-Code mit Ihrem iPhone um die App zu installieren</span>
        <span>Appversion: {APPINFO.version}</span>
        <QrCode size={300} value={manifestUrl}/>
        <span>Ablaufdatum: {formatDate(new Date(APPINFO.expireDate), 'dd.MM.yyyy')}</span>
      </Container>
    </React.Fragment>
  )
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const QrCode = styled(ImportedQRCode)`
  min-height: 300px;
  min-width: 300px;
  max-height: 300px;
  max-width: 300px;
  margin: 50px auto;
`

export default AppDownload
