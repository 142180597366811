import React from 'react'
import styled, { css } from 'styled-components'
import propTypes from 'prop-types'
import Theme from './Theme'
import Grid from '@material-ui/core/Grid'

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  border-radius: 4px;
  background: #FFF;
  border: 1px solid ${Theme.color6};
  display: inline-block;
  font-size: 16px;
  color: ${Theme.color1};
  outline: none;
  padding: 8px 20px 8px 10px;
  ${props => props.error && css`
    border-color: #E02020;
  `}
`

const InputWrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;
  label {
    font-size: 14px;
    padding-bottom: 5px;
  }
  ${props => props.labelPosition === 'top' && css`
    label, input {
      display: block;
    }
  `}
  ${props => props.labelPosition === 'left' && css`
    display: flex;
    label {
      line-height: 38px;
      padding-right: 20px;
    }
  `}
  ${props => props.disabled === true && css`
    input {
      color: ${Theme.color6};
    }
  `}
`

const TextInput = ({ label, labelPosition, error, onChange, disabled, placeholder, value, defaultValue, maxLength, ...props }) => {
  return (
    <InputWrapper labelPosition={labelPosition || 'top'} disabled={disabled}>
      {label &&
        <Grid item xs={labelPosition === 'top' || !labelPosition ? 12 : 2}><label><b>{label}</b></label></Grid>}
      {!disabled &&
        <Grid item xs={labelPosition === 'top' || !labelPosition ? 12 : 10}>
          <Input onChange={onChange} value={value} defaultValue={defaultValue} maxLength={maxLength} placeholder={placeholder} error={error} type={props.type ? props.type : 'text'} />
        </Grid>}
      {disabled &&
        <Grid item xs={labelPosition === 'top' || !labelPosition ? 12 : 10}><Input className='disabled-text-field' readOnly defaultValue={defaultValue} value={props.value} error={error} placeholder={placeholder} type={props.type ? props.type : 'text'} /></Grid>}
    </InputWrapper>
  )
}

TextInput.propTypes = {
  label: propTypes.string,
  labelPosition: propTypes.string,
  error: propTypes.bool,
  disabled: propTypes.bool,
  onChange: propTypes.func
}

export default TextInput
