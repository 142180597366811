import * as React from 'react'

const Check = (props) => {
  return (
    <svg width={props.width || 18} height={props.height || 14} viewBox='0 0 18 14' {...props}>
      <path
        d='M6 11.17L1.83 7 .41 8.41 6 14 18 2 16.59.59z'
        fill={props.fill || '#CDCDCD'}
        fillRule='evenodd'
      />
    </svg>
  )
}

export default Check
