const translation = {
  'name is a required parameter for this action': 'Bitte geben Sie einen Namen an.',
  'personalnummer is a required parameter for this action': 'Bitte geben Sie eine Personalnummer an.',
  'personalnummer is already in use': 'Diese Personalnummer wird bereits verwendet',
  'user not found': 'Dieser Benutzer existiert nicht.',
  'unknown username or password': 'Benutzername oder Passwort falsch.',
  'password is a required parameter for this action': 'Bitte geben Sie ein Passwort an.',
  'Error: title is a required parameter for this action': 'Bitte geben Sie einen Titel an.',
  'Please set a new password!': 'Bitte geben Sie ein neues Passwort an',
  'datenkarte not found': 'Die Datenkarte wurde nicht gefunden.',
  'pruefbaustein not found': 'Der Prüfbaustein wurde nicht gefunden.',
  'pruefprotokoll not found': 'Das Prüfprotokoll wurde nicht gefunden.',
  'pruefprotokollvorlage not found': 'Die Prüfprotokollvorlage wurde nicht gefunden',
  'Error: pruefprotokollvorlage not found': 'Die Prüfprotokollvorlage wurde nicht gefunden',
  'Error while duplicating pruefprotokollvorlage': 'Bei der Duplikation der Prüfprotokollvorlage trat ein Fehler auf.',
  'pruefung not found': 'Die Prüfung wurde nicht gefunden.',
  'vorlagenbaustein not found': 'Der Vorlagenbaustein wurde nicht gefunden',
  'password must have at least 10 characters and consist of at least one uppercase, lowercase letter, a digit and a special character': 'Das Passwort muss aus mehr als 10 Zeichen bestehen. Mindestens ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen müssen enthalten sein.',
  'kennzeichen has to be in format: [XX]-[XX] [XXXX]': 'Kennzeichen müssen dem Format [XX]-[XX] [XXXX] entsprechen.'
}

const translateErrorMessage = (errorMessage) => {
  const result = translation[errorMessage]
  if (!result) {
    return errorMessage
  }
  return result
}

export default translateErrorMessage
