import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Theme from './Theme'

const CustomRadioButton = ({ options, defaultValue, onChange, ...props }) => {
  const [select, setSelect] = useState(defaultValue)

  React.useEffect(() => {
    setSelect(defaultValue)
  }, [defaultValue])

  const handleSelectChange = event => {
    let value = event.target.value
    if (value === 'true') {
      value = true
    }
    if (value === 'false') {
      value = false
    }
    setSelect(value)
    onChange(value)
  }

  return (
    <Wrapper {...props}>
      {options && options.map((option) => {
        const isFalseValue = option.value === 'false' || !option.value
        return (
          <Item key={option.value}>
            <RadioButton
              type='radio'
              name='radio'
              value={option.value}
              alternativeColor={isFalseValue}
              checked={select === option.value}
              onChange={e => handleSelectChange(e)}
            />
            {/* If value is a false value, display alternative color for checked radio button */}
            <RadioButtonLabel alternativeColor={isFalseValue} checked={select === option.value}><Oval /></RadioButtonLabel>
            <Label>{option.label}</Label>
          </Item>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
`

const RadioButtonLabel = styled.label`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0px;
  width: 21px;
  height: 21px;
  border-radius: 45%;
  background: white;
  border: 1px solid #CDCDCD;
  box-shadow: inset -1px 1px 1px 0 #CDCDCD;
  ${props => props.checked && css`
    border: 1px solid #50B4C8;
    background-color: #50B4C8;
    box-shadow: inset -1px 1px 1px 0 rgba(0,0,0,0.3), 0 0 2px 1px rgba(0,102,236,0.5);
  `}
  ${props => props.alternativeColor && props.checked && css`
    border: 1px solid #EB5B3B;
    background-color: #EB5B3B !important; 
    box-shadow: inset -1px 1px 1px 0 rgba(0,0,0,0.3), 0 0 2px 1px rgba(235, 109, 0, 0.5);
  `}
`
const Oval = styled.span`
  height: 8px;
  width: 8px;
  background: #FFF;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 5px;
`

const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 0px;
  margin-top: 0px;
  &:hover ~ ${RadioButtonLabel} {
    ${props => !props.alternativeColor && css`
    border: 1px solid #50B4C8;
    background-color: #50B4C8;
    box-shadow: inset -1px 1px 1px 0 rgba(0,0,0,0.3);
  `}
  ${props => props.alternativeColor && css`
    border: 1px solid #EB5B3B;
    background-color: #EB5B3B !important; 
    box-shadow: inset -1px 1px 1px 0 rgba(0,0,0,0.3);
  `}
    &::after {
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
  &:checked + ${Item} {
    background: ${Theme.color4};
  }
  &:checked + ${RadioButtonLabel} {
    background: ${Theme.color4};
    &::after {
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
`

const Label = styled.div`
  margin-top: -2px;
`

export default CustomRadioButton
