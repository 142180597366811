import React, { useState } from 'react'
import Button from '../common/Button'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import IconWarning from '../../assets/images/warning.svg'
import translateErrorMessage from '../common/translate/errorMessage'
import Spacer from '../common/Spacer'
import Notification from '../common/Notification'

const ConfirmOTPGenerate = ({ user, onGenerate, onCancel, onModalNotification }) => {
  const [errorMessage, setErrorMessage] = useState(null)

  const generate = async () => {
    await setErrorMessage(null)
    try {
      onGenerate()
      onCancel()
      // Notification outside positive
      onModalNotification(`Für '${user.name}, ${user.personalnummer}' wurde ein neues Passwort generiert.`, 'positive')
    } catch (err) {
      // Notification inside negative
      onModalNotification(translateErrorMessage(err.message), 'negative')
    }
  }

  let name
  let personalnummer

  if (user) {
    name = user.name
    personalnummer = user.personalnummer
  }

  return (
    <DestroyModal item xs={12}>
      {errorMessage &&
        <>
          <Notification type='negative' close={() => setErrorMessage(false)}>
            {translateErrorMessage(errorMessage)}
          </Notification>
          <Spacer vertical='20px' />
        </>}
      <SmallText>
        Sind Sie sich sicher für {name}, {personalnummer} ein neues einmaliges Passwort zu generieren?<br />
        Diese Aktion kann nicht rückgängig gemacht werden. <br />
        Der Nutzer muss sich mit dem neu generierten Passwort anmelden.
      </SmallText>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Button tabIndex='3' fullWidth primary onClick={onCancel}>Abbrechen</Button>
        </Grid>
        <Grid item xs={6}>
          <Button tabIndex='2' fullWidth primary onClick={() => generate()} isActive>Neues Passwort generieren</Button>
        </Grid>
      </Grid>
    </DestroyModal>
  )
}

const DestroyModal = styled(Grid)`
  background-image: url(${IconWarning});
  background-position: 50% 15%;
  background-repeat: no-repeat;
  background-size: 66px 57px;
`
const SmallText = styled.p`
  font-size: 14px;
  margin-bottom: 45px;
`

export default ConfirmOTPGenerate
