import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg width={props.width || '34px'} height={props.width || '34px'} viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>x</title>
      <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon transform="rotate(45,17,17)" id="plus" fill={props.color || "#fff"} points="15.0070801 14.9816895 15.0070801 0.103264305 19.0488281 0.103264305 19.0488281 14.9816895 33.8967357 14.9816895 33.8967357 18.9467773 19.0488281 18.9467773 19.0488281 33.9705627 15.0070801 33.9705627 15.0070801 18.9467773 0.0294372515 18.9467773 0.0294372515 14.9816895"></polygon>
      </g>
    </svg>
  )
}

export default SvgComponent
