import Api from './Api'

const api = Api()

const Audit = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    save: async function () {
      const res = await api.exec(`/pruefungen/${id}`,
        {
          method: 'PUT',
          body: JSON.stringify(this)
        })
      if (res.error) {
        throw new Error(res.error)
      }
      return Audit(res)
    },
  }
}

const Audits = ({ ...restProps }) => {
  return {
    ...restProps,
    findAll: async (params) => {
      const res = await api.exec('/pruefungen', {
        method: 'GET',
        body: JSON.stringify(params)
      })
      if (res.error) {
        return res.error
      }
      if (!res.pruefung_list || res.pruefung_list.length < 1) {
        return []
      }
      return res.pruefung_list.map(audit => Audit(audit))
    },
  }
}

export { Audit }
export default Audits
