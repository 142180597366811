import * as React from 'react'

const Copy = (props) => {
  return (
    <svg width={props.width || 18} height={props.height || 18} viewBox='0 0 18 18' {...props}>
      <path
        d='M1.8 14.4H0V1.8C0 .81.81 0 1.8 0h12.6v1.8H1.8v12.6zM16.2 18H5.4c-.99 0-1.8-.81-1.8-1.8V5.4c0-.99.81-1.8 1.8-1.8h10.8c.99 0 1.8.81 1.8 1.8v10.8c0 .99-.81 1.8-1.8 1.8z'
        fill='#CDCDCD'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default Copy
