import React from 'react'
import Button from '../common/Button'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import IconWarning from '../../assets/images/warning.svg'
import translateErrorMessage from '../common/translate/errorMessage'

const Destroy = ({ user, onDestroy, onCancel, onNotification, onModalNotification }) => {
  const destroy = async () => {
    try {
      await user.destroy()
      onCancel()
      onDestroy()
      // Notification outside positive
      onNotification(`Der Nutzer '${user.name}, ${user.personalnummer}' wurde erfolgreich entfernt.`, 'positive')
    } catch (err) {
      // Notification inside negative
      await onModalNotification(translateErrorMessage(err.message), 'negative')
    }
  }

  let name
  let personalnummer

  if (user) {
    name = user.name
    personalnummer = user.personalnummer
  }

  return (
    <DestroyModal item xs={12}>
      <SmallText>
        Sind Sie sich sicher {name}, {personalnummer} als Nutzer zu löschen?<br />
        Diese Aktion kann nicht mehr rückgängig gemacht werden sobald diese ausgeführt worden ist.
      </SmallText>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Button tabIndex='3' fullWidth primary onClick={onCancel}>Abbrechen</Button>
        </Grid>
        <Grid item xs={6}>
          <Button tabIndex='2' fullWidth primary onClick={destroy} isActive>Löschen</Button>
        </Grid>
      </Grid>
    </DestroyModal>
  )
}

const DestroyModal = styled(Grid)`
  background-image: url(${IconWarning});
  background-position: 50% 15%;
  background-repeat: no-repeat;
  background-size: 66px 57px;
`
const SmallText = styled.p`
  font-size: 14px;
  margin-bottom: 45px;
`

export default Destroy
