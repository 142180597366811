import React from 'react'
import styled, { css } from 'styled-components'

import IconClose from '../../assets/images/closing-x.svg.js'

const StyledNotification = styled.div`
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  font-size: 14px;
  line-height: 22px;
  padding: 20px 36px 20px 22px;
  box-sizing: border-box;
  ${props => props.type === 'negative' && css`
    color: #FF0000;
    background-color: rgba(255,220,212,0.9);
    border-color: #E02020;
  `}
  ${props => props.type === 'positive' && css`
    color: #0091FF;
    background-color: #E5F4FF;
    border-color: #0091FF;
  `}
`

const CloseButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  top: 24px;
  right: 20px;
  cursor: pointer;
  outline: none;
`

const Notification = ({ close, ...props }) => {
  return (
    <>
      <StyledNotification {...props}>
        {props.children}
        <CloseButton onClick={() => close()}>
          {props.type === 'negative' &&
            <IconClose fill='#E02020' height='13px' />}
          {props.type === 'positive' &&
            <IconClose fill='#0091FF' height='13px' />}
        </CloseButton>
      </StyledNotification>
    </>
  )
}

export default Notification
