import React from 'react'
import TextInput from './TextInput'
import IconSearch from '../../assets/images/search.svg'
import styled from 'styled-components'

const SearchInput = ({ onChange, onClick, ...props }) => {
  return (
    <SearchInputWrapper>
      <TextInput type='text' onChange={onChange} {...props} />
      <SearchButton onClick={onClick}>
        <img width='16px' src={IconSearch} alt='Lupe' />
      </SearchButton>
    </SearchInputWrapper>

  )
}

const SearchInputWrapper = styled.div`
  position: relative;
`

const SearchButton = styled.button`
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 60px;
  background: transparent;
`

export default SearchInput
