import React from 'react'
import productimage from './img/404.png';
import styled from 'styled-components'

const ProductgroupImage = styled.div`
    background-image: url(${productimage});
    top: 100px;
    height: 320px;
    background-repeat: no-repeat;
    background-position: center;
`

const NotFound = () => {

  return (
      <ProductgroupImage></ProductgroupImage>
  )
}

export default NotFound