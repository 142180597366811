import React from 'react'

function ClosingX (props) {
  return (
    <svg viewBox='0 0 28 28' {...props}>
      <path
        d='M14.033 11.2L24.553.68l2.859 2.858-10.52 10.52 10.498 10.5-2.803 2.803-10.5-10.499L3.464 27.486.606 24.628 11.23 14.004.639 3.414 3.442.61z'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default ClosingX
