import React, { useEffect, useState } from 'react'
import DataSource from '../common/ApiHandler/TestReportTemplates'
import Button from '../common/Button'
import PageHeader from '../common/PageHeader'
import List from './List'
import Filter from './Filter'
import Edit from './Edit'
import LoadingIndicator from '../common/LoadingIndicator'
import translateErrorMessage from '../common/translate/errorMessage'

import Notification from '../common/Notification'
import Spacer from '../common/Spacer'

const TestReportTemplateOverview = (props) => {
  const [testReportTemplates, setTestReportTemplates] = useState([])
  const [filter, setFilter] = useState({
    category: 'all'
  })
  const [order, setOrder] = useState(true)
  const [loading, setLoading] = useState(false)

  const [notification, setNotification] = useState(false)

  const loadData = async () => {
    setLoading(true)
    const params = {}
    if (filter) {
      if (filter.category !== 'all') {
        params.typ = filter.category
      }
      if (filter.search) {
        params.title = filter.search
      }
      if (filter.filter) {
        switch (filter.filter) {
          case 'published':
            params.published = !!order
            break
          case 'title':
            params.order = `title&order=${order ? 'DESC' : 'ASC'}`
            break
          case 'updatedAt':
            params.order = `updatedAt&order=${order ? 'DESC' : 'ASC'}`
            break
          default:
            break
        }
      }
    }
    const templateList = await DataSource.findAll(params)
    setTestReportTemplates(templateList)
    setLoading(false)
  }

  /**
   * Remove notifications after 5 seconds
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification(false)
    }, 5000)
    return () => clearTimeout(timer)
  }, [notification])

  const possibleSuccessMessage = () => {
    // window.params change
    // als parameter in routing?
    if (window.location.hash.includes('successCreate')) {
      setNotification({
        type: 'positive',
        message: 'Die Prüfprotokollvorlage wurde erfolgreich erstellt.'
      })
    }
    if (window.location.hash.includes('successUpdate')) {
      setNotification({
        type: 'positive',
        message: 'Die Prüfprotokollvorlage wurde erfolgreich gespeichert.'
      })
    }
    if (window.location.hash.includes('successDelete')) {
      setNotification({
        type: 'positive',
        message: 'Die Prüfprotokollvorlage wurde erfolgreich gelöscht.'
      })
    }
  }

  useEffect(() => {
    possibleSuccessMessage()
    loadData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (filter) {
      loadData()
    }
    // eslint-disable-next-line
  }, [filter, order])

  /* onChange Listeners */
  const onDuplicate = async (template) => {
    try {
      const duplicatedTemplate = await template.duplicate()
      props.history.push(`/pruefprotokollvorlagen/id/${duplicatedTemplate.id}?successDuplicate`)
    } catch (e) {
      setNotification({
        type: 'negative',
        message: translateErrorMessage(e)
      })
    }
  }

  // false = asc
  // true = desc
  const onFilterChange = (filterPath, filterValue) => {
    const updatedFilter = { ...filter, [filterPath]: filterValue }
    setFilter(updatedFilter)
  }

  const onOrderChange = (order) => {
    setOrder(order)
  }

  const onSelect = async (data) => {
    await DataSource.findOne(data.id)
    props.history.push(`/pruefprotokollvorlagen/id/${data.id}`)
  }

  return (
    <>
      <PageHeader>
        <h1>Prüfprotokollvorlagen</h1>
        <Button alternative to='/pruefprotokollvorlagen/neu'>Neu</Button>
      </PageHeader>
      <Filter onChange={onFilterChange} filter={filter} onOrderChange={onOrderChange} />
      {notification &&
        <>
          <Spacer vertical='20px' />
          <Notification type={notification.type} close={() => setNotification(false)}>
            {notification.message}
          </Notification>
          <Spacer vertical='20px' />
        </>}
      {loading &&
        <LoadingIndicator />}
      {!loading &&
        <>
          {testReportTemplates.length > 0 &&
            <List data={testReportTemplates} onSelect={onSelect} onDuplicate={onDuplicate} />}
          {testReportTemplates.length === 0 &&
            <h2 style={{ display: 'block', textAlign: 'center' }}>Keine Ergebnisse
              {!!filter.search &&
                <>
                  &nbsp;für Suchtext '{filter.search}'
                </>}
            </h2>}
        </>}

    </>
  )
}

export { Edit }
export default TestReportTemplateOverview
