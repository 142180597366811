import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Title, Value } from './StyledComponents'
import { Collapse } from 'react-collapse'
import IconReparaturErforderlich from '../../assets/images/blazon_re.svg'
import IconInOrdnung from '../../assets/images/blazon_io.svg'
import IconGemessen from '../../assets/images/blazon_ge.svg'
import IconNichtGeprueft from '../../assets/images/blazon_ng.svg'
import IconHaken from '../../assets/images/haken.svg'
import IconFailed from '../../assets/images/x.svg'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Grid from '@material-ui/core/Grid'
import Config from '../common/ApiHandler/Config'
import { format as formatDate } from 'date-fns'
import LoadingIndicator from '../common/LoadingIndicator'
import ImportedQRCode from 'qrcode.react'
import Spacer from '../common/Spacer'
import './collapseStyle.css'
import TextInput from '../common/TextInput'
import Button from '../common/Button'
import { useSession } from '../common/ApiHandler/Session'
import SessionStore from '../common/ApiHandler/SessionStore'

const Abschlussbericht = (props) => {
  const { session } = useSession()
  const fakeSessionStore = SessionStore('fake')
  const fakeSession = fakeSessionStore.get()
  const [pruefung, setPruefung] = useState({})
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(true)
  const [datenkarte, setDatenkarte] = useState([])
  const [unusedProtocols, setUnusedProtocols] = useState([])
  const [expandedProtocols, setExpandedProtocols] = useState({})
  const [kdid, setKdid] = useState(fakeSession)
  const [blocking, setBlocking] = useState(!session)

  useEffect(() => {
    const loadData = async () => {
      // use fetch as this happens without login
      try {
        let response = await fetch(`${Config.backend}/pruefungen/${props.match.params.berichtid}`, {
          method: 'GET',
          headers: {
            Authorization: `apikey ${Config.apikey}`,
            Accept: 'application/json'
          }
        })
        if (response.status !== 200) {
          if (response.status === 404) {
            props.history.replace('/not-found')
          }
          throw new Error('Could not get pruefung')
        }
        response = await response.json()
        const pruefung = response.pruefung
        const images = pruefung.pruefprotokolls.reduce((acc, protokoll) => [...acc, ...protokoll.pruefprotokoll_images.map(image => `${Config.backend}${Config.pathforimages}/${image.image}`)], [])
        pruefung.datenkarte.qrurl = `${window.origin}/#/datenkarte/${pruefung.datenkarte.qrid}`
        setPruefung(response.pruefung)
        setImages(images)
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    loadData()
  }, [props.history, props.match.params.berichtid])

  useEffect(() => {
    if (kdid === datenkarte.kdid) {
      setBlocking(false)
    }
  }, [datenkarte, kdid])

  // get all posts for inspection
  useEffect(() => {
    const loadData = async () => {
      // use fetch as this happens without login
      try {
        let response = await fetch(`${Config.backend}/datenkarten/${pruefung.datenkarte.qrid}`, {
          method: 'GET',
          headers: {
            Authorization: `apikey ${Config.apikey}`,
            Accept: 'application/json'
          }
        })
        if (response.status !== 200) {
          if (response.status === 404) {
            props.history.replace('/not-found')
          }
          throw new Error('Could not get datenkarte')
        }
        response = await response.json()
        setDatenkarte(response.datenkarte)
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    if (pruefung && pruefung.datenkarte) {
      loadData()
    }
  }, [props.history, pruefung])

  useEffect(() => {
    const getUncheckedPositions = () => {
      const protocols = pruefung.pruefprotokolls
      const uncheckedProtocols = [...datenkarte.pruefprotokollvorlages]

      datenkarte.pruefprotokollvorlages.forEach((template, index) => {
        protocols.forEach(protocol => {
          if (template.id === protocol.pruefprotokollvorlagenId) {
            uncheckedProtocols.splice(index, 1)
          }
        })
      })
      setUnusedProtocols(uncheckedProtocols)
    }
    if (pruefung && pruefung.pruefprotokolls && datenkarte && datenkarte.pruefprotokollvorlages) {
      getUncheckedPositions()
    }
  }, [pruefung, datenkarte])

  const submit = async (e) => {
    if (kdid === datenkarte.kdid) {
      setBlocking(false)
    }
  }

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  const handleChange = (event, field) => {
    setKdid(event.target.value)
    fakeSessionStore.set(event.target.value)
  }

  const handlePrint = async () => {
    const allExpanded = pruefung.pruefprotokolls.reduce((acc, curr) => {
      return { ...acc, [curr.id]: true }
    }, {})
    setExpandedProtocols(allExpanded)
    await sleep(200)
    window.print()
  }

  return (
    <>
      {loading &&
        <LoadingIndicator />}
      {!loading && blocking &&
        <Grid
          container
          justify='center'
          alignItems='center'
        >
          <Grid item xs={12}>&nbsp;</Grid>
          <Grid item xs={8} sm={6} md={5} lg={4}>
            <LoginWrapper onSubmit={(e) => submit(e)}>
              <Spacer vertical='10px' />
              <TextInput labelPosition='top' onChange={(e) => handleChange(e, 'Kundennummer')} label='Kundennummer' />
              <Spacer vertical='20px' />
              <Button fullWidth primary isActive>Einloggen</Button>
            </LoginWrapper>
          </Grid>
        </Grid>}
      {!loading && !blocking &&
        <ViewAbschlussbericht>
          <FixedGrid>
            <Centered>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <h1>Abschlussbericht {formatDate(new Date(pruefung.updatedAt), 'dd.MM.yyyy')}</h1>
                  <PrintButton
                    className='no-print'
                    onClick={handlePrint}
                  >
                    Vollständig ausdrucken
                  </PrintButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <QrCode value={pruefung.datenkarte.qrurl} />
                </Grid>
              </Grid>
            </Centered>
          </FixedGrid>
          <Bar>
            <FixedGrid>
              <Centered>Stammdatenkarte</Centered>
            </FixedGrid>
          </Bar>
          <FixedGrid>
            <Centered id='stammdatenkarte'>
              {pruefung.datenkarte.type === 'fahrzeug' &&
                <>
                  <Title>Kundennummer</Title>
                  <Value>{pruefung.datenkarte.kdid}</Value>
                  <Title>Fahrzeugart</Title>
                  <Value>{pruefung.datenkarte.fahrzeugart}</Value>
                  <Title>Hersteller</Title>
                  <Value>{pruefung.datenkarte.hersteller}</Value>
                  <Title>Modell</Title>
                  <Value>{pruefung.datenkarte.model}</Value>
                  <Title>Aufbau</Title>
                  <Value>{pruefung.datenkarte.aufbau}</Value>
                  <Title>Ausbauer</Title>
                  <Value>{pruefung.datenkarte.ausbauer}</Value>
                  <Title>Ausbaunummer</Title>
                  <Value>{pruefung.datenkarte.ausbaunummer}</Value>
                  <Title>Fahrgestellnummer</Title>
                  <Value>{pruefung.datenkarte.fahrgestellnr}</Value>
                  <Title>Kilometerstand</Title>
                  <Value>{pruefung.datenkarte.kmstand}</Value>
                </>}
              {pruefung.datenkarte.type === 'medizinprodukt' &&
                <>
                  <Title>Kundennummer</Title>
                  <Value>{pruefung.datenkarte.kdid}</Value>
                  <Title>Produkt</Title>
                  <Value>{pruefung.datenkarte.produkt}</Value>
                  <Title>Hersteller</Title>
                  <Value>{pruefung.datenkarte.hersteller}</Value>
                  <Title>Modell</Title>
                  <Value>{pruefung.datenkarte.model}</Value>
                  <Title>Baujahr</Title>
                  <Value>{pruefung.datenkarte.baujahr}</Value>
                  <Title>Seriennummer</Title>
                  <Value>{pruefung.datenkarte.seriennummer}</Value>
                  <Title>Standort</Title>
                  <Value>{pruefung.datenkarte.standort}</Value>
                </>}
            </Centered>
          </FixedGrid>
          <Bar>
            <FixedGrid>
              <Centered>Geprüfte Posten</Centered>
            </FixedGrid>
          </Bar>
          <FixedGrid>
            <Centered id='gepruefte-posten'>
              {pruefung.pruefprotokolls.map(protokoll => {
                let result
                switch (protokoll.status) {
                  case 'positive': result = 'io'; break
                  case 'negative': result = 're'; break
                  default:
                  case 'started': result = 'ng'; break
                }
                return (
                  <>
                    <PruefProtokoll key={protokoll.id} onClick={() => setExpandedProtocols({ ...expandedProtocols, [protokoll.id]: !expandedProtocols[protokoll.id] })}>
                      <PruefProtokollTopContainer>
                        <Pruefergebnis result={result} />
                        <Prueftitel>{protokoll.title}</Prueftitel>
                        <ExpandIcon expanded={expandedProtocols[protokoll.id]} />
                      </PruefProtokollTopContainer>
                      <Collapse isOpened={expandedProtocols[protokoll.id]}>
                        {!!protokoll && !!protokoll.pruefbausteins && protokoll.pruefbausteins.map(baustein => {
                          let logo
                          switch (baustein.status) {
                            case 'positive': logo = 'io'; break
                            case 'negative': logo = 're'; break
                            case null: logo = baustein.typ === 'measuring' ? 'ge' : 'ng'; break
                            default: logo = 'ng'; break
                          }
                          let titleText = baustein.title || 'Kein Titel'
                          if (baustein.typ === 'measuring') {
                            titleText += `: ${baustein.freitext} ${baustein.einheit}`
                          }
                          return (
                            <BausteinContainer>
                              <Bausteinergebnis result={logo} />
                              <BausteinTitle>{titleText}</BausteinTitle>
                            </BausteinContainer>
                          )
                        })}
                      </Collapse>
                    </PruefProtokoll>
                  </>
                )
              })}
              {unusedProtocols.map(protokoll => {
                return (
                  <PruefProtokoll key={protokoll.id}>
                    <PruefProtokollTopContainer>
                      <Pruefergebnis result='ng' />
                      <Prueftitel>{protokoll.title}</Prueftitel>
                    </PruefProtokollTopContainer>
                  </PruefProtokoll>
                )
              })}
            </Centered>
          </FixedGrid>
          <Bar>
            <FixedGrid>
              <Centered>Gesamtergebnis</Centered>
            </FixedGrid>
          </Bar>
          <FixedGrid>
            <Centered>
              <div style={{ display: 'flex' }}>
                <GesamtIcon result={pruefung.status === 'positive' ? 'io' : 're'} />
                <Gesamtergebnis>{pruefung.status === 'positive' ? 'In Ordnung!' : 'Nicht Bestanden'}</Gesamtergebnis>
              </div>
              <Spacer />
              {pruefung.needsRepair &&
                <div style={{ display: 'flex' }}>
                  <GesamtIcon result='nr' />
                  <Gesamtergebnis>Reperatur benötigt</Gesamtergebnis>
                </div>}

            </Centered>
          </FixedGrid>
          {pruefung.bemerkung !== null && [
            <Bar>
              <FixedGrid>
                <Centered>Kommentar</Centered>
              </FixedGrid>
            </Bar>,
            <FixedGrid>
              <Centered>
                <Comment>{pruefung.bemerkung}</Comment>
              </Centered>
            </FixedGrid>
          ]}
          {!!images.length &&
            <>
              <Bar>
                <FixedGrid>
                  <Centered>Bilder</Centered>
                </FixedGrid>
              </Bar>
              <FixedGrid>
                <Centered>
                  {images.map(url => (<Image key={`image_${url}`} src={url} className='bild' />))}
                </Centered>
              </FixedGrid>
            </>}
        </ViewAbschlussbericht>}
    </>
  )
}

const ViewAbschlussbericht = styled.div`
  width: 100%;
  h1 {
    font-size: 24px;
    font-family: 'Open Sans Bold';
  }
  h4 {
    padding-top: 10px;
    font-size: 18px;
  }
  `

const PrintButton = styled.button`
  border: 2px solid rgba(252,130,41,0.9);
  padding: 0.25em 1em;
  border-radius: 3px;
  margin-top: 70px;
  font-size: 1.25em;
  background: #EFEFEF;

  &:hover {
    background: white;
  }
`

const LoginWrapper = styled.form`
display: block;
padding: 30px 22px 30px 22px;
border-radius: 16px;
background-color: #FFFFFF;
box-shadow: 0 8px 16px 0 #E0E2E4;
`

const GesamtIcon = styled.div`
display: block;
    width: 60px;
    height 60px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    ${props => props.result === 'io' && css`
      background-size: 25px 29px;
      background-color: #EB5B3B;
      background-image: url(${IconHaken});
    `}
    ${props => props.result === 're' && css`
      background-size: 25px 29px;
      background-color: black;
      background-image: url(${IconFailed});
    `}
    ${props => props.result === 'nr' && css`
      background-size: 25px 29px;
      background-color: black;
      background-image: url(${IconFailed});
    `}
`

const Gesamtergebnis = styled.div`
  line-height: 60px;
  height: 60px;
  font-family: 'Open Sans SemiBold';
  font-size: 20px;
  padding-left: 35px;
`

const Comment = styled.div`
  font-family: 'Open Sans SemiBold';
  font-size: 20px;
  color: #333;
`

const Bar = styled.div`
  height: 40px;
  background-color: rgba(252,130,41,0.9);
  color: #FFF;
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
  margin-top: 30px;
  margin-bottom: 20px;
`

const QrCode = styled(ImportedQRCode)`
  height: 75px;
  width: 75px;
  background: green;
  margin-left: calc(100% - 128px);
  @media screen and (max-width: 600px) {
    margin: 30px auto 0 auto;
  }
`

const PruefProtokoll = styled.div`
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
`

const PruefProtokollTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const ExpandIcon = styled(ChevronRightIcon)`
  transition: all 500ms;
  ${props => props.expanded && css`
    transform: rotate(90deg);
  `}
  color: rgba(252,130,41,0.9);
`

const BausteinContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 5%;
`

const BausteinTitle = styled.div`
  font-family: 'Open Sans';
  font-size: 18px;
  color: #333;
  margin-left: 15px;
  flex: 1;
  margin-bottom: 10px;
`

const Pruefergebnis = styled.div`
  width: 75px;
  height: 75px;
  background-size: container;
  background-repeat: no-repeat;
  ${props => props.result === 'io' && css`
    background-image: url(${IconInOrdnung});
  `}
  ${props => props.result === 'ge' && css`
    background-image: url(${IconGemessen});
  `}
  ${props => props.result === 'ng' && css`
    background-image: url(${IconNichtGeprueft});
  `}
  ${props => props.result === 're' && css`
    background-image: url(${IconReparaturErforderlich});
  `}
`

const Bausteinergebnis = styled(Pruefergebnis)`
  width: 50px;
  height: 50px;
`

const Prueftitel = styled.div`
  font-family: 'Open Sans SemiBold';
  font-size: 20px;
  color: #333;
  flex: 1;
  margin-left: 35px;
`

const FixedGrid = styled.div`
  flex: 1;
  justify-content: center;
  max-width: 780px;
  margin: 0 auto;
`

const Centered = styled.div`
  max-width: 91.67%;
  margin: 0 auto;
`

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-top: 5;
  margin-bottom: 15;
`

export default Abschlussbericht
