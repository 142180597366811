import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import SearchInput from '../common/SearchInput'
import Select from '../common/Select'
import Grid from '@material-ui/core/Grid'
import SortIcon from '../../assets/svgcomponents/Sort'

const FILTER_OPTIONS = [
  {
    label: 'Titel',
    value: 'title'
  },
  {
    label: 'Status',
    value: 'published'
  },
  {
    label: 'Änderungsdatum',
    value: 'updatedAt'
  }
]

const TemplateFilter = ({ onChange, onOrderChange, ...props }) => {
  /* Order true => Asc; False => Desc */
  const [order, setOrder] = useState(true)
  const [sort, setSort] = useState(false)

  const orderChange = () => {
    setOrder(!order)
    onOrderChange(!order)
  }

  const onSortFilterChange = (value) => {
    setSort(value)
    onChange('filter', value)
  }

  return (
    <>
      <ListNavigation>
        <ListSelect className={props.filter.category === 'all' ? 'active' : ''} onClick={() => { onChange('category', 'all') }}>Alle</ListSelect>
        <ListSelect className={props.filter.category === 'medizinprodukt' ? 'active' : ''} onClick={() => { onChange('category', 'medizinprodukt') }}>Medizinprodukt</ListSelect>
        <ListSelect className={props.filter.category === 'fahrzeug' ? 'active' : ''} onClick={() => { onChange('category', 'fahrzeug') }}>Fahrzeuge</ListSelect>
      </ListNavigation>
      <FilterSearch>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <SearchInput onChange={(e) => onChange('search', e.target.value)} />
          </Grid>
          <Grid item xs={3}>
            <FilterBoxWrapper>
              <FilterBox onChange={(option) => onSortFilterChange(option.value)} options={FILTER_OPTIONS} />
              <FilterButton filterActive={!!sort} onClick={() => orderChange()}>
                <SortIcon style={!order ? { transform: 'rotate(180deg)' } : {}} />
              </FilterButton>
            </FilterBoxWrapper>
          </Grid>
        </Grid>
      </FilterSearch>
    </>
  )
}

const FilterSearch = styled.div`
  border-bottom: 1px solid #CDCDCD;
  padding: 20px 20px 10px 20px;
  display: flex;
  `

const FilterBox = styled(Select)`

`

const FilterBoxWrapper = styled.div`
  height: 40px;
  position: relative;
  display: flex;
`

const fillColorActive = `
svg {
  path {
    fill: #FC8229;
  }
}
`

const FilterButton = styled.button`
  border: none;
  width: 62px;
  height: 40px;
  border: 1px solid #CDCDCD;
  border-radius: 0 4px 4px 0;
  position: relative;
  right: 2px;
  background: #FFF;
  ${props => props.filterActive && css`
    ${fillColorActive}
  `}
  &:hover {
    ${fillColorActive}
  }
`

const ListNavigation = styled.nav`
  box-sizing: border-box;
  height: 45px;
  border-bottom: 1px solid #CDCDCD;
`

const ListSelect = styled.button`
  box-sizing: border-box;
  height: 44px;
  text-align: center;
  padding: 10px 20px;
  border: none;
  background: none;
  font-size: 16px;
  &.active {
    border-bottom: 1px solid #F88A39;
    font-weight: 600;
  }
`

export default TemplateFilter
