/**
 * Store Theme Related Data
 * Colors
 * Spacings
 * Font Sizes
 *
 */

const Theme = {
  color1: '#393939',
  color2: 'rgba(252,130,41,0.9)',
  color3: 'rgba(235,91,59,0.9)',
  color4: '#50B4C8',
  color5: '#FFF',
  color6: '#CDCDCD',
  gradient1: 'linear-gradient(135deg, #FC8229 0%, #EB5B3B 100%)'
}

export default Theme
