import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import propTypes from 'prop-types'
import Theme from './Theme'

const ButtonPrimaryActive = `
  background: ${Theme.color2};
  color: ${Theme.color5};
  font-family: 'Open Sans';
  font-weight: 600;
  border: 1px solid ${Theme.color2};
`

const ButtonAlternativeActive = `
  background: rgba(235,91,59,0.2);
`

const ButtonAppearance = styled.div`
${props => props.fullWidth && css`
  width: 100%;
`}
a {
  line-height: 36px;
}  
a, button {
    position: relative;
    cursor: pointer;  
    border-radius: 6px;
    height: 42px;
    box-sizing: border-box;
    display: block;
    text-align: center;
    font-size: 16px;
    width: ${props => props.fullWidth ? '100%' : '290px'};
    text-decoration: none;
    font-family: 'Open Sans';
    ${props => props.primary && css`
      border: 1px solid rgba(205,205,205,1);
      box-shadow: 0 8px 16px 0 #E0E2E4;
      background: ${Theme.color5};
      color: ${Theme.color1};
    `}
    ${props => props.alternative && css`
      border: 1px solid ${Theme.color3};
      background-color: transparent;
      color: ${Theme.color3};
      font-weight: 600;
      &:after {
        font-weight: normal;
        content: "+";
        color: ${Theme.color3};
        font-size: 26px;
        position: absolute;
        top: 2px;
        right: 18px;
        display: block;
        background: transparent;
      }
    `}
    ${props => props.isActive && props.primary && css`
      ${ButtonPrimaryActive}
    `}
    ${props => props.isActive && props.alternative && css`
      ${ButtonAlternativeActive}
    `}
    &:hover {
      ${props => props.alternative && css`
      ${ButtonAlternativeActive}
    `}
    ${props => props.primary && css`
      ${ButtonPrimaryActive}
    `}
    }
    span {
      vertical-align: middle;
      line-height: normal;
      display: inline-block;
    }
  }
  a:focus, button:focus {
    outline: none;
  }
`

/**
 * Button has 2 Variants and you can put in internal, external links and actions
*/

const Button = ({ primary, alternative, isActive, fullWidth, ...props }) => {
  return (
    <ButtonAppearance primary={primary} alternative={alternative} isActive={isActive} fullWidth={fullWidth}>
      {/* internal link */}
      {props.to &&
        <Link to={props.to}><span>{props.children}</span></Link>}
      {/* External Link */}
      {props.href &&
        <a href={props.href}><span>{props.children}</span></a>}
      {/* Action Button */}
      {!props.href && !props.to &&
        <button {...props}><span>{props.children}</span></button>}
    </ButtonAppearance>
  )
}

Button.propTypes = {
  alternative: propTypes.bool,
  primary: propTypes.bool,
  isActive: propTypes.bool
}

export default Button
