import React from 'react'
import styled from 'styled-components'

import builderInputTypes from '../../common/Config/BuilderInputTypes'

import Theme from '../../common/Theme'
import DisabledCheckbox from '../../common/DisabledCheckbox'
import TextInput from '../../common/TextInput'
import Textarea from '../../common/Textarea'
import Select from '../../common/Select'
import Spacer from '../../common/Spacer'
import IconClose from '../../../assets/images/closing-x.svg.js'

import { Draggable } from 'react-beautiful-dnd'

import Box from '@material-ui/core/Box'

const protocol = ({ index, onChange, protocol, ...restProps }) => {
  /* Updates protocol field */
  const update = (field, value) => {
    onChange('update', { ...protocol, [field]: value })
  }

  const add = (type) => {
    onChange('add', { type })
  }

  /* Removes itself */
  const remove = () => {
    onChange('remove', protocol)
  }

  const ClosingButton = ({ protocol }) => {
    return (
      <CloseButton onClick={() => remove()}>
        <IconClose fill={Theme.color6} height='13px' />
      </CloseButton>
    )
  }

  return (
    <>
      {protocol && protocol.id &&
        <Draggable draggableId={protocol.id} index={index}>
          {(provided) => (
            <BuilderWrapper {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
              {protocol.type !== 'default' &&
                <>
                  <ClosingButton protocol={protocol} />
                  <BuilderContainer>
                    {protocol.type === 'inspection' &&
                      <>
                        <BuilderLabel>Prüfaufgabe</BuilderLabel>
                        <div style={{ width: '66%' }}>
                          <BuilderTextarea
                            id={Math.random()}
                            onChange={e => update('text', e.target.value)}
                            defaultValue={protocol.text || ''}
                            placeholder='Beschreibung...'
                            titlePlaceholder='Titel...'
                            onTitleChange={e => update('title', e.target.value)}
                            titleDefaultValue={protocol.title || ''}
                          />
                          <Spacer vertical='5px' />
                          <Box display='flex' justifyContent='flex-start'>
                            <DisabledCheckbox title='In Ordnung' />
                            <Spacer horizontal='90px' />
                            <DisabledCheckbox title='Reparatur erforderlich' />
                          </Box>
                        </div>
                      </>}
                    {protocol.type === 'maintenance' &&
                      <>
                        <BuilderLabel>Wartungsaufgabe</BuilderLabel>
                        <div style={{ width: '66%' }}>
                          <BuilderTextarea
                            id={Math.random()}
                            onChange={e => update('text', e.target.value)}
                            defaultValue={protocol.text || ''}
                            placeholder='Beschreibung...'
                            titlePlaceholder='Titel...'
                            onTitleChange={e => update('title', e.target.value)}
                            titleDefaultValue={protocol.title || ''}
                          />
                          <Box display='flex' justifyContent='flex-start'>
                            <DisabledCheckbox title='Ok' />
                          </Box>
                        </div>
                      </>}
                    {protocol.type === 'measuring' &&
                      <>
                        <BuilderLabel>Messaufgabe</BuilderLabel>
                        <div style={{ width: '66%' }}>
                          <BuilderTextarea
                            id={Math.random()}
                            onChange={e => update('text', e.target.value)}
                            defaultValue={protocol.text || ''}
                            placeholder='Beschreibung...'
                            titlePlaceholder='Titel...'
                            onTitleChange={e => update('title', e.target.value)}
                            titleDefaultValue={protocol.title || ''}
                          />
                          <BuilderTextInput disabled placeholder='Freitexteingabe (max. 50 Zeichen)' />
                          <BuilderTextInput maxLength='50' onChange={e => update('einheit', e.target.value)} defaultValue={protocol.einheit || ''} placeholder='Einheit...' />
                        </div>
                      </>}
                  </BuilderContainer>
                </>}
              {protocol.type === 'default' &&
                <>
                  {/* <AddButton onClick={() => addNewField()}>+</AddButton> */}
                  <AddButton>+</AddButton>
                  <BuilderInputSelect onChange={e => add(e.value)} placeholder=' - - ' name='builderInputType' options={builderInputTypes} />
                </>}
            </BuilderWrapper>
          )}
        </Draggable>}
    </>
  )
}

const BuilderWrapper = styled.div`
  padding: 20px 104px 20px 20px;
  border-radius: 2px;
  background-color: #FAFAFA;
  box-shadow: 0 8px 16px 0 #E0E2E4;
  margin-bottom: 25px;
  position: relative;
`

const BuilderContainer = styled(Box)`
  padding: 0px 0px 0px 22px;
  display: flex;
`

const BuilderLabel = styled.label`
  width: 33%;
  font-family: 'Open Sans SemiBold';
  color: ${Theme.color1};
  line-height: 38px;
  box-sizing: border-box;
  padding-right: 20px;
`

const BuilderInputSelect = styled(Select)`
  max-width: 300px;
`

const BuilderTextInput = styled(TextInput)`
`

const BuilderTextarea = styled(Textarea)`
`

const CloseButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  outline: none;
`

const AddButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  outline: none;
  color: ${Theme.color6};
  font-size: 26px;
  font-family: 'Open Sans';
`

export default protocol
