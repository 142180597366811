import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import Check from '../../assets/svgcomponents/Check'
import Copy from '../../assets/svgcomponents/Copy'

import Theme from './Theme'

const CopyFieldWrapper = styled.div`
  box-sizing: border-box;
  height: 40px;
  border: 1px solid ${Theme.color6};
  border-radius: 4px;
  background-color: #F1F1F1;
  position: relative;
`

const ReadOnlyInput = styled.input`
  box-shadow: inset 0 1px 1px 1px rgba(0,0,0,0.3);
  background: #F1F1F1;
  height: 38px;
  box-sizing: border-box;
  border: none;
  width: 100%;
  font-style: italic;
  display: block;
  font-size: 14px;
  color: ${Theme.color1}; 
  padding-left: 20px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`

const CopyActionButton = styled.button`
  width: 50px;
  height: 38px;
  box-sizing: border-box;
  background: ${Theme.color5};
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid ${Theme.color6};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`

const CopyIcon = styled(Copy)`
  &:hover {
    path {
      fill: #FC8229;
    }
  }
`

const CheckIcon = styled(Check)`
  path {
    fill: #50B4C8;
  }
`

const CopyField = ({ value, ...props }) => {
  const [copySuccess, setCopySuccess] = useState(false)
  const inputRef = useRef(null)

  const copyToClipboard = (e) => {
    inputRef.current.select()
    document.execCommand('copy')
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    setCopySuccess(true)
    e.target.focus()
    resetCopyStatus()
  }

  const resetCopyStatus = () => {
    const timer = setTimeout(() => {
      setCopySuccess(false)
    }, 5000)
    return () => clearTimeout(timer)
  }

  return (
    <CopyFieldWrapper style={props.style}>
      <ReadOnlyInput ref={inputRef} defaultValue={value} readOnly />
      <CopyActionButton onClick={copyToClipboard}>
        {copySuccess &&
          <CheckIcon />}
        {!copySuccess &&
          <CopyIcon />}
      </CopyActionButton>
    </CopyFieldWrapper>
  )
}

export default CopyField
